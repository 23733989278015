import React, { Component } from "react";
import {
  Row,
  Col,
  Tabs,
  Tab,
  Form,
  Card,
  Table,
  Button,
  Container,
  Breadcrumb,
} from "react-bootstrap";
import "./materialRequest.css";
import { FaHome } from "react-icons/fa";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import ClosedOrder from "./ClosedOrder";
import MaterialService from "../../Services/MaterialService";
import MaterialOrder from "../../Services/MaterialOrder";
export default class MaterialRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      materials: [],
      material: String,
      quantity: Number,
      stores: [],
      currentPage: 0,
      totalPages: null,
      totalItems: null,
    };
  }
  componentDidMount() {
    console.log("Mount");
    MaterialService.getAllMaterialsWithoutPagination().then((res) => {
      this.setState({ materials: res.data.materials || [] });
      console.log("Mount2");
      console.log(res.data);
    });
    console.log("Mount");
    let activePage = this.state.currentPage;
    MaterialOrder.getAllOpenStore(activePage).then((res) => {
      this.setState({ stores: res.data.storeOrders || [] });
      console.log("Mount2");
      console.log(res.data);
      const totalPages = res.data.totalPages;
      const totalItems = res.data.totalItems;

      this.setState({ totalPages: totalPages });
      this.setState({ totalItems: totalItems });
    });
  }
  handlePageChange(pageNumber) {
    let page = pageNumber - 1;
    this.setState({ currentPage: page });
    MaterialOrder.getAllOpenStore(page).then((res) => {
      this.setState({ stores: res.data.storeOrders || [] });
      console.log("Mount2");
      console.log(res.data);
      const totalPages = res.data.totalPages;
      const totalItems = res.data.totalItems;

      this.setState({ totalPages: totalPages });
      this.setState({ totalItems: totalItems });
    });
  }
  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      console.log(this.state);
    });
  };
  submitMaterialRequest = (e) => {
    e.preventDefault();
    let materialOrder = {
      materialId: this.state.material,
      quantity: this.state.quantity,
      remark: "remark added",
    };
    console.log(materialOrder);
    MaterialOrder.addStoreOrder(materialOrder)
      .then((res) => {
        console.log("success");
        console.log(res.data);
        NotificationManager.success("Material has been added", "Success");
        let activePage = this.state.currentPage;
        MaterialOrder.getAllOpenStore(activePage).then((res) => {
          this.setState({ stores: res.data.storeOrders || [] });
          console.log("Mount2");
          console.log(res.data);
          const totalPages = res.data.totalPages;
          const totalItems = res.data.totalItems;

          this.setState({ totalPages: totalPages });
          this.setState({ totalItems: totalItems });
        });
      })
      .catch((res) => {
        console.log("failure");
        console.log(res.data);
      });
  };
  render() {
    let { materials, material, quantity } = this.state;

    const handleTableChange = (type, { page, sizePerPage }) => {
      this.handlePageChange(page);
    };

    const columns = [
      {
        dataField: "materialName",
        text: "Material Name",
      },
      {
        dataField: "orderQuantity",
        text: "Quantity",
      },
      {
        dataField: "operatorName",
        text: "Operator Name",
      },
      {
        dataField: "insertedDate",
        text: "Request Date",
      },
    ];

    return (
      <>
        <div className="gh-bgcolor gh-dashboard-bgimg">
          <Container fluid>
            <Row className="mx-auto">
              <Col>
                <h4 className="py-4 h4 d-inline-block">Material Request</h4>
                <Breadcrumb className="gh-breadcrumb d-none">
                  <Breadcrumb.Item
                    linkProps={{ to: "/materialRequest" }}
                    linkAs={Link}
                  >
                    <FaHome />
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Material Request</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>
          </Container>

          <Container fluid className="homeComponent pb-5">
            <Row className="mx-auto">
              <Col>
                <div className="mb-3 mx-2">
                  <Form>
                    <Row>
                      <Col lg={4} md={6} sm={6} xs={12}>
                        <Form.Group controlId="material">
                          <Form.Label>Material Name</Form.Label>
                          <Form.Control
                            as="select"
                            name="material"
                            onChange={this.changeHandler}
                            value={material}
                          >
                            <option>Select Material</option>
                            {materials.map((material, idx) => (
                              <option key={idx} value={material.materialId}>
                                {material.materialName}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col lg={4} md={6} sm={6} xs={12}>
                        <Form.Group controlId="quantity">
                          <Form.Label>Quantity </Form.Label>
                          <Form.Control
                            type="number"
                            name="quantity"
                            onChange={this.changeHandler}
                            value={quantity}
                            placeholder="Quantity"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col lg={4} md={6} sm={6} xs={12}>
                        <Button
                          className="alignDown mb-3"
                          variant="success"
                          type="submit"
                          onClick={this.submitMaterialRequest}
                        >
                          Send Request
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </div>

                <div className="mt-2 mb-2">
                  <Tabs
                    defaultActiveKey="openOrder"
                    id="controlled-tab-example"
                  >
                    <Tab eventKey="openOrder" title="Open Order">
                      <Container fluid className="mainStore p-0">
                        <PaginationProvider
                          pagination={paginationFactory({
                            custom: true,
                            page: this.state.currentPage + 1,
                            sizePerPage: 10,
                            paginationSize: 10,
                            alwaysShowAllBtns: true,
                            totalSize: this.state.totalItems,
                          })}
                        >
                          {({ paginationProps, paginationTableProps }) => (
                            <div className="w-100">
                              <BootstrapTable
                                remote
                                keyField="id"
                                bordered={false}
                                data={this.state.stores}
                                columns={columns}
                                wrapperClasses="gh-table"
                                onTableChange={handleTableChange}
                                {...paginationTableProps}
                              />

                              <div className="d-flex justify-content-center">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </div>
                          )}
                        </PaginationProvider>
                        <NotificationContainer />
                      </Container>
                    </Tab>
                    <Tab eventKey="closedOrder" title="Closed Order">
                      <ClosedOrder />
                    </Tab>
                  </Tabs>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}
