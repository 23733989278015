import React, { Component } from "react";
import {
  Row,
  Table,
  Card,
  Container,
  Form,
  Breadcrumb,
  Col,
  Button,
  Modal,
  Alert,
} from "react-bootstrap";
import { Chart } from "react-google-charts";
import { Link } from "react-router-dom";
import { FaHome } from "react-icons/fa";
import CustomerOrderService from "../../Services/CustomerOrderService";
import ZoneService from "../../Services/ZoneService";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";

import SunburstChart from "./SunburstChart";

export default class ReportsComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sunburstSelectedCityName: "",
    };
    this.sunburstCityChart = "";
    this.chartsContainer = {};
    this.chartsData = {
      top5salesChart1: {
        2020: {
          label: [
            '4" Pulp Molded Round Pot',
            'SP 100 - 4" Pot -  Black',
            'SP 90 -  3.5" Pot -   Terracotta',
            'SP 90 -  3.5" Pot -  Black',
            'SP 90 - 3.5" Pot - White',
          ],
          data: [6780, 32545.695, 28322.23774, 2777.0202, 4998.63636],
        },
        2021: {
          label: [
            '19.5" Hanger',
            "Saucers",
            'SP 100 - 4" Pot -  Black',
            'SP 150 - 6" Pot -  Black',
            'SP 90 -  3.5" Pot -  Black',
          ],
          data: [74636.5904, 33262.60768, 83353.3426, 196993.7475, 118388.075],
        },
        2022: {
          label: [
            "Saucers",
            'SP 100 - 4" Pot -   Green',
            'SP 100 - 4" Pot -  Black',
            'SP 90 -  3.5" Pot -  Black',
            'ST 400 / 10 - 10 pack Tray RND for 4" RND pot - Black',
          ],
          data: [49543.2992, 94975.3926, 559380.8886, 92294.94672, 701555.4058],
        },
        2023: {
          label: [
            'SCP 350 - 3.5" Square Pot - Black',
            'SP 100 - 4" Pot -  Black',
            'SP 140 - 5.5" Pot -  Black',
            'SP 150 - 6" Pot -  Black',
            'SP 90 -  3.5" Pot -  Black',
          ],
          data: [25039.54886, 94060.4203, 34322.4, 79879.86272, 90068.62185],
        },
        2024: {
          label: [
            'SJ 1204 ( 8.5 X 20") Inserts',
            'SJ 1204 (8.5 X 20") Flats',
            'SP 100 - 4" Pot -   Green',
            'SP 100 - 4" Pot -  Black',
            'SP 230 - 9" Pot - Terracotta',
          ],
          data: [157463.944, 213905.458, 28619.20515, 169882.0247, 87559.68],
        },
      },
      bottom5salesChart1: {
        2020: {
          label: [
            '22.5" - 4 Prong Hanger',
            'HB 1002 - 10"Hanging Basket - Dark Gray',
            'HB 1201 - 12"Hanging Basket - Chocolate',
            'Pulp pot 4" mold',
            "Pulp Tray 10 pack - Mold",
          ],
          data: [474.6, 1369.56, 3017.1, 23504, 23249.75],
        },
        2021: {
          label: [
            "Fresh  Pomegranates (count 8)",
            "Fresh  Pomegranates (count 9)",
            "FRUBITES - Freezedried - Pomegranate",
            "FRUBITES Freezedried - Apple",
            'PMPT 10 - 10 pack tray for 4" PMG round pot',
          ],
          data: [48762, 19504.8, 1541.2, 6070.4, 935.64],
        },
        2022: {
          label: [
            "Alphonso Mangoes (Box / Pack of 12)",
            'HB 1201 - 12"Hanging Basket - Chocolate',
            'ST 550 / 6 - 6 pack Tray RND for 5.5" pot  - Black',
            "ST 630  / 6A - 6 pack Tray RND  - Black",
            'ST 750  / 3 - 3 pack Tray RND for 7.5" pot - Black',
          ],
          data: [16000, 12814.2, 3147.84, 4394.3553, 7503.3],
        },
        2023: {
          label: [
            '14" Square Coco coir Planter',
            'C 170*160 -  6"Injection mold - Terracotta',
            "PT 288 S - Plug Tray 288 S  - Black",
            'SIT 350 / 18 - 18 count tray  for 3.5 " Injection SQ Pot - Black',
            "ST 433 / 10 - 10 pack Tray RND for 4.33 pot  - Black",
          ],
          data: [18875.52, 1844.16, 6299.712, 3982.8432, 8729.8376],
        },
        2024: {
          label: [
            '11" Infinite - Round pot - GWW-G - H070',
            '13" Fleur Round Pot - L013- Black',
            '14" Lattice hanging basket',
            '14" Lettuce - Black - H 140',
            "RCP 260/ RCP 1000",
          ],
          data: [688.509, 3240.162, 2538.432, 4686.336, 1149.696],
        },
      },
      top5salesChart2: {
        2020: {
          label: [
            '4" Pulp Molded Round Pot',
            'SP 100 - 4" Pot -  Black',
            'SP 90 -  3.5" Pot -   Terracotta',
            'SP 90 -  3.5" Pot -  Black',
            'SP 90 - 3.5" Pot - White',
          ],
          data: [120000, 822900, 1318608, 133200, 239760],
        },
        2021: {
          label: [
            '19.5" Hanger',
            "Saucers",
            'SP 100 - 4" Pot -  Black',
            'SP 150 - 6" Pot -  Black',
            'SP 90 -  3.5" Pot -  Black',
          ],
          data: [424192, 424192, 1522180, 1212940, 3212640],
        },
        2022: {
          label: [
            "Saucers",
            'SP 100 - 4" Pot -   Green',
            'SP 100 - 4" Pot -  Black',
            'SP 90 -  3.5" Pot -  Black',
            'ST 400 / 10 - 10 pack Tray RND for 4" RND pot - Black',
          ],
          data: [782530, 1398760, 8824530, 2145420, 827640],
        },
        2023: {
          label: [
            'SCP 350 - 3.5" Square Pot - Black',
            'SP 100 - 4" Pot -  Black',
            'SP 140 - 5.5" Pot -  Black',
            'SP 150 - 6" Pot -  Black',
            'SP 90 -  3.5" Pot -  Black',
          ],
          data: [259776, 1398980, 302400, 470400, 2222370],
        },
        2024: {
          label: [
            'SJ 1204 ( 8.5 X 20") Inserts',
            'SJ 1204 (8.5 X 20") Flats',
            'SP 100 - 4" Pot -   Green',
            'SP 100 - 4" Pot -  Black',
            'SP 230 - 9" Pot - Terracotta',
          ],
          data: [505340, 509420, 421685, 2931225, 420960],
        },
      },
      bottom5salesChart2: {
        2020: {
          label: [
            '22.5" - 4 Prong Hanger',
            'HB 1002 - 10"Hanging Basket - Dark Gray',
            'HB 1201 - 12"Hanging Basket - Chocolate',
            'Pulp pot 4" mold',
            "Pulp Tray 10 pack - Mold",
          ],
          data: [3000, 2020, 3000, 1, 1],
        },
        2021: {
          label: [
            "Fresh  Pomegranates (count 8)",
            "Fresh  Pomegranates (count 9)",
            "FRUBITES - Freezedried - Pomegranate",
            "FRUBITES Freezedried - Apple",
            'PMPT 10 - 10 pack tray for 4" PMG round pot',
          ],
          data: [4320, 1728, 772, 3052, 1200],
        },
        2022: {
          label: [
            "Alphonso Mangoes (Box / Pack of 12)",
            'HB 1201 - 12"Hanging Basket - Chocolate',
            'ST 550 / 6 - 6 pack Tray RND for 5.5" pot  - Black',
            "ST 630  / 6A - 6 pack Tray RND  - Black",
            'ST 750  / 3 - 3 pack Tray RND for 7.5" pot - Black',
          ],
          data: [2000, 9072, 4800, 2700, 9000],
        },
        2023: {
          label: [
            '14" Square Coco coir Planter',
            'C 170*160 -  6"Injection mold - Terracotta',
            "PT 288 S - Plug Tray 288 S  - Black",
            'SIT 350 / 18 - 18 count tray  for 3.5 " Injection SQ Pot - Black',
            "ST 433 / 10 - 10 pack Tray RND for 4.33 pot  - Black",
          ],
          data: [4200, 10200, 11520, 3360, 8800],
        },
        2024: {
          label: [
            '11" Infinite - Round pot - GWW-G - H070',
            '13" Fleur Round Pot - L013- Black',
            '14" Lattice hanging basket',
            '14" Lettuce - Black - H 140',
            "RCP 260/ RCP 1000",
          ],
          data: [500, 3000, 1440, 2880, 2880],
        },
      },
    };

    const sunburst_rawdata = [
      {
        country: "United States",
        city: "Kalamazoo",
        customerName: "Wenke Greenhouses",
        regionName: "ON",
        storeName: "Bill Dean",
      },
      {
        country: "United States",
        city: "Ontario",
        customerName: "CSK LLC",
        regionName: "91761",
        storeName: "CSK LLC",
      },
      {
        country: "Canada",
        city: "Welland",
        customerName: "Vermeer Flowers",
        regionName: "L3C 3C8",
        storeName: "Pelham Road Store",
      },
      {
        country: "Canada",
        city: "Fenwick",
        customerName: "Slappendel Greenhouse 1",
        regionName: " L0S 1C0",
        storeName: "Slappendel Greenhouses 1",
      },
      {
        country: "Canada",
        city: "Niagara-on-the-Lake",
        customerName: "Seaway Farms",
        regionName: "L0S 1J0",
        storeName: "Eileen Pillitteri",
      },
      {
        country: "Canada",
        city: "Burford",
        customerName: "Willowbrook Nurseries Inc.",
        regionName: "ON",
        storeName: "Willowbrook Nurseries Inc.",
      },
      {
        country: "Canada",
        city: "Lowsbank",
        customerName: "Snippe Greenhouses ",
        regionName: "N0A1K0",
        storeName: "Snippe Greenhouses",
      },
      {
        country: "Canada",
        city: "St Catherines",
        customerName: "Gregory Floral Inc",
        regionName: "L2R6P9",
        storeName: "Gregory Floral Inc",
      },
      {
        country: "Canada",
        city: "Dunnville",
        customerName: "Maple Greenhouses",
        regionName: "N0A 1K0",
        storeName: "Maple Greenhuses",
      },
      {
        country: "Canada",
        city: "Niagara On the Lake",
        customerName: "Willy's Greenhouses Ltd.",
        regionName: "L0S 1JO",
        storeName: "Willy's Greenhouses Ltd.",
      },
      {
        country: "United States",
        city: "Kalamazoo",
        customerName: "Stan Ouding Bedding plants",
        regionName: "49048",
        storeName: "Stan Ouding Bedding plants",
      },
      {
        country: "Canada",
        city: "Fenwick",
        customerName: "Greenfield Gardens",
        regionName: " L0S 1C0",
        storeName: "Greenfield Gardens",
      },
      {
        country: "Canada",
        city: "Fenwick",
        customerName: "Slappendel Greenhouses Inc",
        regionName: "L0S 1C0",
        storeName: "Slappendel Greenhouse",
      },
      {
        country: "Canada",
        city: "St. Catherines",
        customerName: "Vanderhoeven Greenhouses",
        regionName: "L2R 7K6",
        storeName: "Vanderhoeven Greenhouses",
      },
      {
        country: "Canada",
        city: "Jordan Station",
        customerName: "Midway Farms",
        regionName: "L0R 1S0",
        storeName: "Midway Farms",
      },
      {
        country: "Canada",
        city: "Waterdown",
        customerName: "Scharringa Greenhouses",
        regionName: "L0R2H2",
        storeName: "Scharringa Greenhouses",
      },
    ];

    function convertToHierarchy(data) {
      const root = { name: "Blitzer", children: [] };
      const countryMap = new Map();

      data.forEach((item) => {
        // Country level
        if (!countryMap.has(item.country)) {
          const countryNode = { name: item.country, children: [] };
          countryMap.set(item.country, countryNode);
          root.children.push(countryNode);
        }
        const countryNode = countryMap.get(item.country);

        // City level
        let cityNode = countryNode.children.find(
          (child) => child.name === item.city
        );
        if (!cityNode) {
          cityNode = { name: item.city, children: [] };
          countryNode.children.push(cityNode);
        }

        // Customer level
        let customerNode = cityNode.children.find(
          (child) => child.name === item.customerName
        );
        if (!customerNode) {
          customerNode = { name: item.customerName, children: [] };
          cityNode.children.push(customerNode);
        }

        // Store level (leaf node)
        if (
          !customerNode.children.some((child) => child.name === item.storeName)
        ) {
          customerNode.children.push({ name: item.storeName, value: 1 });
        }
      });

      return root;
    }

    // Example usage:
    // const inputData = [ ... ]; // Your JSON array from the previous step
    // const hierarchicalData = convertToHierarchy(inputData);
    // console.log(JSON.stringify(hierarchicalData, null, 2));

    this.sunburstData = convertToHierarchy(sunburst_rawdata);
  }

  componentDidMount() {
    let that = this;
    let commonChartDataAndOption = {
      type: "bar",
      data: {
        labels: [], // "Product X", "Product P", "Product Q"
        datasets: [
          {
            data: [], // 40, 35, 25
            backgroundColor: [
              "rgba(255, 99, 132, 0.8)",
              "rgba(75, 192, 192, 0.8)",
              "rgba(54, 162, 235, 0.8)",
              "rgba(255, 205, 86, 0.8)",
              "rgba(153, 102, 255, 0.8)",
            ],
          },
        ],
      },
      options: {
        plugins: {
          title: {
            display: true,
            text: "", // Year 2024
          },
          legend: {
            display: false,
          },
        },
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    };

    const ctxtopsales = new window.Chart(document.getElementById("topsales"), {
      type: "bar",
      data: {
        labels: ["2020", "2021", "2022", "2023", "2024"],
        datasets: [
          {
            type: "line",
            label: "Sales",
            fill: false,
            data: [3198042, 8303720, 20334969, 6259534, 7989038],
            backgroundColor: "#5ca9df",
            borderColor: "#5ca9df",
            pointStyle: "circle",
            pointRadius: 10,
            pointHoverRadius: 15,
          },
          {
            label: "Revenue",
            data: [
              337507.4326, 1842567.858, 3824680.971, 897544.3337, 1951938.498,
            ],
            backgroundColor: "#ffb1c1",
            borderColor: "#ff0000",
            fill: true,
            borderWidth: 1,
            pointStyle: "circle",
            pointRadius: 10,
            pointHoverRadius: 15,
          },
        ],
      },
      options: {},
    });

    this.chartsContainer.top5salesChart1 = new window.Chart(
      document.getElementById("top5salesChart1"),
      commonChartDataAndOption
    );

    this.chartsContainer.bottom5salesChart1 = new window.Chart(
      document.getElementById("bottom5salesChart1"),
      commonChartDataAndOption
    );

    this.chartsContainer.top5salesChart2 = new window.Chart(
      document.getElementById("top5salesChart2"),
      commonChartDataAndOption
    );

    this.chartsContainer.bottom5salesChart2 = new window.Chart(
      document.getElementById("bottom5salesChart2"),
      commonChartDataAndOption
    );

    this.sunburstCityChart = new window.Chart(
      document.getElementById("sunburstCityChart"),
      {
        type: "bar",
        data: {
          labels: ["2024"],
          datasets: [],
          fillColor: "rgba(220,220,220,0.5)",
        },
        options: {
          indexAxis: "y",
          scales: {
            y: {
              beginAtZero: true,
            },
            x: [
              {
                barThickness: 6, // number (pixels) or 'flex'
                maxBarThickness: 8, // number (pixels)
              },
            ],
          },
          plugins: {
            datalabels: {
              anchor: "end", // Position the label at the end of the bar
              align: "end", // Align the label with the bar edge
              color: "#000", // Label color
              formatter: (value) => value, // Display the value itself
            },
          },
        },
        plugins: [ChartDataLabels],
      }
    );

    // Year Dropdown
    let selectDropDown = document.querySelectorAll(".salesChartsDD");
    this.updateChartData(selectDropDown[0]);
    this.updateChartData(selectDropDown[1]);
    this.updateChartData(selectDropDown[2]);
    this.updateChartData(selectDropDown[3]);
  }

  loadChart = (p) => {
    this.setState({
      sunburstSelectedCityName:
        p.parent.parent.data.name +
        " > " +
        p.parent.data.name +
        " > " +
        p.data.name,
    });

    window.ppp = this.sunburstCityChart;
    this.sunburstCityChart.data.datasets = [
      { label: "SP 150 - 6  Pot -  Black", borderWidth: 1, data: [312] },
      { label: "SP 100 - 4  Pot -  Black", borderWidth: 1, data: [302] },
      { label: "SP 100 - 4  Pot -  Black", borderWidth: 1, data: [200] },
      { label: "SP 100 - 4  Pot -  Black", borderWidth: 1, data: [52] },
      { label: "SP 100 - 4  Pot -  Black", borderWidth: 1, data: [82] },

      { label: "1SP 150 - 6  Pot -  Black", borderWidth: 1, data: [12] },
      { label: "1SP 100 - 4  Pot -  Black", borderWidth: 1, data: [2] },
      { label: "1SP 100 - 4  Pot -  Black", borderWidth: 1, data: [200] },
      { label: "1SP 100 - 4  Pot -  Black", borderWidth: 1, data: [52] },
      { label: "1SP 100 - 4  Pot -  Black", borderWidth: 1, data: [82] },

      { label: "2SP 150 - 6  Pot -  Black", borderWidth: 1, data: [12] },
      { label: "2SP 100 - 4  Pot -  Black", borderWidth: 1, data: [2] },
      { label: "2SP 100 - 4  Pot -  Black", borderWidth: 1, data: [200] },
      { label: "2SP 100 - 4  Pot -  Black", borderWidth: 1, data: [52] },
      { label: "2SP 100 - 4  Pot -  Black", borderWidth: 1, data: [82] },
    ];
    this.sunburstCityChart.update();
    this.sunburstCityChart.resize();
  };

  updateChartData = (ele) => {
    let chartId = ele.getAttribute("data-labelid");
    let year = ele.value;

    let chart = this.chartsContainer[chartId];

    chart.options.plugins.title.text = year;

    chart.data.labels = this.chartsData[chartId][year].label;
    chart.data.datasets[0].data = this.chartsData[chartId][year].data;

    chart.update();
  };

  render() {
    return (
      <>
        <div className="gh-bgcolor gh-dashboard-bgimg">
          <Container fluid>
            <Row className="mx-auto">
              <Col>
                <h4 className="py-4 h4 d-inline-block">Reports</h4>
                <>
                  <h5>Total Sales - Sales and Revenue </h5>
                  <div>
                    <canvas id="topsales" height="80px"></canvas>
                  </div>
                </>
              </Col>
            </Row>
          </Container>

          <Container fluid className="pb-5 mt-5">
            <Row className="mx-auto py-3 ">
              <div className="col-12">
                <h5>Sunburst Hierarchical Report</h5>
                <div className="text-center auto">
                  <SunburstChart
                    data={this.sunburstData}
                    loadChart={this.loadChart}
                  />
                </div>
                <h5>{this.state.sunburstSelectedCityName}</h5>
                <div
                  style={{
                    display:
                      this.state.sunburstSelectedCityName == ""
                        ? "none"
                        : "block",
                    width: "80vw",
                    height: "600px",
                    margin: "0 auto",
                    padding: "0 20px",
                    overflowY: "auto",
                    overflowX: "hidden",
                  }}
                >
                  <canvas id="sunburstCityChart"></canvas>
                </div>
              </div>
            </Row>
          </Container>

          <Container fluid className="">
            <Row className="mx-auto py-3 ">
              <div className="col-6">
                <h5>
                  Top 5 - Sales Revenue Wise
                  <select
                    className="float-right salesChartsDD"
                    onChange={(event) => this.updateChartData(event.target)}
                    data-labelid="top5salesChart1"
                  >
                    <option value="2024">2024</option>
                    <option value="2023">2023</option>
                    <option value="2022">2022</option>
                    <option value="2021">2021</option>
                    <option value="2020">2020</option>
                  </select>
                </h5>
                <div className="text-center mx-auto">
                  <canvas id="top5salesChart1"></canvas>
                </div>
              </div>

              <div className="col-6">
                <h5>
                  Bottom 5 - Sales Revenue Wise
                  <select
                    className="float-right salesChartsDD"
                    onChange={(event) => this.updateChartData(event.target)}
                    data-labelid="bottom5salesChart1"
                  >
                    <option value="2024">2024</option>
                    <option value="2023">2023</option>
                    <option value="2022">2022</option>
                    <option value="2021">2021</option>
                    <option value="2020">2020</option>
                  </select>
                </h5>
                <div className="text-center mx-auto">
                  <canvas id="bottom5salesChart1"></canvas>
                </div>
              </div>
            </Row>
          </Container>

          <Container fluid className="pb-5">
            <Row className="mx-auto py-3 ">
              <div className="col-6">
                <h5>
                  Top 5 - Sales Quantity Wise
                  <select
                    className="float-right salesChartsDD"
                    onChange={(event) => this.updateChartData(event.target)}
                    data-labelid="top5salesChart2"
                  >
                    <option value="2024">2024</option>
                    <option value="2023">2023</option>
                    <option value="2022">2022</option>
                    <option value="2021">2021</option>
                    <option value="2020">2020</option>
                  </select>
                </h5>
                <div className="text-center mx-auto">
                  <canvas id="top5salesChart2"></canvas>
                </div>
              </div>

              <div className="col-6">
                <h5>
                  Bottom 5 - Sales Quantity Wise
                  <select
                    className="float-right salesChartsDD"
                    onChange={(event) => this.updateChartData(event.target)}
                    data-labelid="bottom5salesChart2"
                  >
                    <option value="2024">2024</option>
                    <option value="2023">2023</option>
                    <option value="2022">2022</option>
                    <option value="2021">2021</option>
                    <option value="2020">2020</option>
                  </select>
                </h5>
                <div className="text-center mx-auto">
                  <canvas id="bottom5salesChart2"></canvas>
                </div>
              </div>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}
