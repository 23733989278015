import React, { Component } from "react";
import {
  Row,
  Table,
  Col,
  Button,
  Card,
  Container,
  Modal,
  OverlayTrigger,
  Tooltip,
  Breadcrumb,
  Form,
} from "react-bootstrap";
import { FaHome } from "react-icons/fa";
import { BiEdit } from "react-icons/bi";
import { MdDeleteForever } from "react-icons/md";
import { Link } from "react-router-dom";
import "../Zone/zone.css";
import RecipeService from "../../../Services/RecipeService";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";

export default class AddNewRecipe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zones: [],
      productList: [],
      productRecipeList: [],
      selectedProductId: "",
      selectedRecipeId: "",
      materialCategoryList: [],
      selectedProductIdandName: {},
      materialList: [],
      customers: [],
      currentPage: 0,
      totalPages: null,
      totalItems: null,
      varientsTableData: [],
      materialListToShow: [],
      materialName: String,
      materialCategoryName: String,
      quantity: Number,
      cost: Number,
      materialId: "",
      materialCategoryId: Number,
      // productName: String,
      variantName: "",
      selectedProductName: "",
    };
  }
  openModal = () => {
    this.setState({ openModal: true });
  };

  closeModal = () => {
    this.setState({ openModal: false });
  };

  componentDidMount() {
    //for product list-dropdown
    RecipeService.getProductList("").then((res) => {
      this.setState({ productList: res.data.products || [] });
    });

    RecipeService.getMaterialCatogoryList().then((res) => {
      this.setState({ materialCategoryList: res.data || [] });
    });

    RecipeService.getMaterialList().then((res) => {
      this.setState({ materialList: res.data.materials || [] });
    });
  }

  //1st dropdown product list-change handler
  productListchangeHandler = (e) => {
    const arr = this.state.productList.filter(
      (val) => val.productId == e.target.value
    );
    this.setState({ selectedProductIdandName: arr[0] });

    RecipeService.getProductRecipeList(e.target.value).then((res) => {
      this.setState({ productRecipeList: res.data || [] });
    });
  };

  //3rd dropdown product list-change handler
  materialCategoryListchangeHandler = (e) => {
    const filteredArr = this.state.materialList.filter((val) => {
      return val.categoryId == e.target.value;
    });

    this.setState({ materialListToShow: filteredArr });
    this.setState({ materialCategoryName: e.target.value }, () => {});
  };

  //4th dropdown material list
  materialChangeHandler = (e) => {
    this.setState({ materialId: e.target.value }, () => {});
  };

  //quantity textbox value
  quantityChangeHandler = (e) => {
    this.setState({ quantity: e.target.value }, () => {});
  };

  //cost textbox value
  costChangeHandler = (e) => {
    this.setState({ cost: e.target.value }, () => {});
  };

  //variant name change handler
  variantChangeHandler = (e) => {
    this.setState({ variantName: e.target.value }, () => {});
  };

  //api call-add material button click
  handleAddMaterialBtnClick = (e) => {
    e.preventDefault();
    const arr = this.state.materialListToShow.filter((val) => {
      return val.materialId == this.state.materialId;
    });

    let recipe = {
      materialName: arr[0].materialName,
      materialCategoryName: arr[0].categoryName,
      quantity: this.state.quantity,
      cost: this.state.cost,
      materialId: arr[0].materialId,
      materialCategoryId: arr[0].categoryId,
    };

    const data = {
      productId: this.state.selectedProductIdandName.productId,
      productName: this.state.selectedProductIdandName.productName,
      variantName: this.state.variantName,
      variantItems: [recipe],
    };

    console.log("2nd page data:-", data);
    RecipeService.addMaterial(data)
      .then((res) => {
        console.log("material added successfully");
        console.log("recID:---------", res?.data?.recipeId);
        this.setState({ selectedRecipeId: res?.data?.recipeId });
        this.setState({
          varientsTableData: res?.data?.variantItems,
        });
        // this.setState({ productName: "" });
        // this.setState({ materialCategory: "" });
        // this.setState({ materialListToShowName: "" });
        // this.setState({ materialId: "" });
        // this.setState({ quantity: "" });
        // this.setState({ cost: "" });
        // this.setState({ recipeVariantName: "" });
      })
      .catch((res) => {
        console.log("material not added it is failed");
      });
  };

  //to remove record from table
  removeRecord = (val) => {
    const filteredRecord = this.state.varientsTableData.filter(
      (item) => item.id != val
    );

    let recipeId = this.state.selectedRecipeId;
    let id = val;

    RecipeService.removeMaterial(recipeId, id).then(() => {
      this.setState({ varientsTableData: filteredRecord });
    });
  };

  render() {
    let {
      productName,
      quantity,
      cost,
      currentPage,
      productRecipeList,
      materialCategoryList,
      materialList,
      displayProductList,
      recipeVariantList,
      recipeVariantName,
      productList,
      recipeName,
      materialCategory,
      materialListName,
      materialListToShow,
      materialListToShowName,
    } = this.state;

    let that = this;

    const handleTableChange = (type, { page, sizePerPage }) => {
      this.handlePageChange(page);
    };

    const columns = [
      {
        dataField: "materialCategoryName",
        text: "Material Category",
      },
      {
        dataField: "materialName",
        text: "Material Name",
      },
      {
        dataField: "quantiy",
        text: "Material Quantity",
      },
      {
        dataField: "cost",
        text: "Material Cost",
      },
      {
        dataField: "Action",
        isDummyField: true,
        text: "Action",
        formatter: function (cell, row) {
          return (
            <Button onClick={() => that.removeRecord(row.id)}>Remove</Button>
          );
        },
      },
    ];

    return (
      <>
        <div className="gh-bgcolor gh-dashboard-bgimg">
          <Container fluid>
            <Row className="mx-auto">
              <Col>
                <h4 className="py-4 h4 d-inline-block">Recipe</h4>
                <Breadcrumb className="gh-breadcrumb d-none">
                  <Breadcrumb.Item linkProps={{ to: "/home" }} linkAs={Link}>
                    <FaHome />
                  </Breadcrumb.Item>
                  <Breadcrumb.Item linkProps={{ to: "/home" }} linkAs={Link}>
                    Configuration
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Add Recipe</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>
          </Container>

          <Container fluid className="homeComponent pb-5">
            <Row className="mx-auto">
              <Col>
                <div className="mx-1">
                  <Form>
                    <Row>
                      <Col lg={6} md={4} sm={4} xs={12}>
                        <Form.Group controlId="productName">
                          <Form.Label>Display Product Name </Form.Label>
                          <Form.Control
                            as="select"
                            name="productName"
                            onChange={this.productListchangeHandler}
                            value={productName}
                          >
                            <option key="blankChoice" hidden value>
                              Select Product
                            </option>
                            {productList.map((val, idx) => (
                              <option key={idx} value={val.productId}>
                                {val.productName}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      </Col>

                      <Col lg={6} md={6} sm={6} xs={12}>
                        <Form.Group controlId="cost">
                          <Form.Label>Recipe Variant Name</Form.Label>
                          <Form.Control
                            type="text"
                            name="cost"
                            onChange={this.variantChangeHandler}
                            value={recipeVariantName}
                            placeholder="Enter Recipe Variant Name"
                            autoComplete="off"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={3} md={6} sm={6} xs={12}>
                        <Form.Group controlId="materialCategoryList">
                          <Form.Label>Material Category List </Form.Label>
                          <Form.Control
                            as="select"
                            name="materialCategoryList"
                            onChange={this.materialCategoryListchangeHandler}
                            value={materialCategory}
                          >
                            <option key="blankChoice" hidden value>
                              Select Category
                            </option>
                            {materialCategoryList.map((val, idx) => (
                              <option key={idx} value={val.categoryId}>
                                {val.categoryName}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      </Col>

                      <Col lg={3} md={6} sm={6} xs={12}>
                        <Form.Group controlId="materialList">
                          <Form.Label>Material List </Form.Label>
                          <Form.Control
                            as="select"
                            name="materialList"
                            onChange={this.materialChangeHandler}
                            value={materialListToShowName}
                          >
                            <option key="blankChoice" hidden value>
                              Select Material
                            </option>
                            {materialListToShow?.map((val, idx) => (
                              <option key={idx} value={val.materialId}>
                                {val.materialName}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      </Col>

                      <Col lg={3} md={6} sm={6} xs={12}>
                        <Form.Group controlId="quantity">
                          <Form.Label>Quantity</Form.Label>
                          <Form.Control
                            type="number"
                            name="quantity"
                            onChange={this.quantityChangeHandler}
                            value={quantity}
                            placeholder="Enter Quantity"
                            autoComplete="off"
                          ></Form.Control>
                        </Form.Group>
                      </Col>

                      <Col lg={3} md={6} sm={6} xs={12}>
                        <Form.Group controlId="cost">
                          <Form.Label>Cost</Form.Label>
                          <Form.Control
                            type="number"
                            name="cost"
                            onChange={this.costChangeHandler}
                            value={cost}
                            placeholder="Enter Cost"
                            autoComplete="off"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col
                        lg={12}
                        md={3}
                        sm={6}
                        xs={12}
                        className="text-right mt-2"
                      >
                        <Button
                          variant="gh-success"
                          onClick={this.handleAddMaterialBtnClick}
                        >
                          Add Material
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </div>

                <div className="d-flex justify-content-center mt-5 mb-2">
                  <PaginationProvider
                    pagination={paginationFactory({
                      custom: true,
                      page: this.state.currentPage + 1,
                      sizePerPage: 10,
                      paginationSize: 10,
                      alwaysShowAllBtns: true,
                      totalSize: this.state.totalItems,
                    })}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <div className="w-100">
                        <BootstrapTable
                          remote
                          bordered={false}
                          keyField="id"
                          data={this.state.varientsTableData}
                          columns={columns}
                          wrapperClasses="gh-table"
                          onTableChange={handleTableChange}
                          {...paginationTableProps}
                        />

                        <div className="d-flex justify-content-center">
                          <PaginationListStandalone {...paginationProps} />
                        </div>
                        {/* <pre>{JSON.stringify(paginationProps, null, 2) }</pre> */}
                      </div>
                    )}
                  </PaginationProvider>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <Modal
          className="textCenter"
          show={this.state.openModal}
          onHide={this.closeModal}
        >
          <Modal.Header closeButton>Delete</Modal.Header>
          <Modal.Body>Do you really want to delete?</Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={this.closeModal}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
