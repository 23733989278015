import React, { Component } from "react";
import {
  Card,
  Container,
  Table,
  Button,
  Breadcrumb,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { FaHome } from "react-icons/fa";
import { BiEdit } from "react-icons/bi";
import { Link } from "react-router-dom";
import "./tax.css";
import MasterDataService from "../../../Services/MasterDataService";

export default class TaxComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tax: {},
    };
  }
  componentDidMount() {
    console.log("Mount");
    MasterDataService.getTax().then((res) => {
      this.setState({ tax: res.data || [] });
      console.log("Mount2");
      console.log(res.data);
    });
  }
  render() {
    let { tax } = this.state;
    return (
      <>
        <div className="gh-bgcolor gh-dashboard-bgimg">
          <Container fluid>
            <Row className="mx-auto">
              <Col>
                <h4 className="py-4 h4 d-inline-block">HST - Tax Component</h4>
                <Breadcrumb className="gh-breadcrumb d-none">
                  <Breadcrumb.Item linkProps={{ to: "/home" }} linkAs={Link}>
                    <FaHome />
                  </Breadcrumb.Item>
                  <Breadcrumb.Item linkProps={{ to: "/home" }} linkAs={Link}>
                    Configuration
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Tax</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>
          </Container>

          <Container fluid className="homeComponent pb-5">
            <Row className="mx-auto" style={{ height: "300px" }}>
              <Col>
                <div className="d-flex justify-content-center mt-4 mb-2">
                  <Table bordered={false} className="gh-table">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>HST %</th>
                        <th>Tax Number</th>
                        <th>Business Number</th>
                        <th>Inserted Date</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>{tax.tax}</td>
                        <td>{tax.taxNumber}</td>
                        <td>{tax.businessNumber}</td>
                        <td>{tax.insertedDate}</td>
                        <td>
                          <div
                            className="gh-tooltip top text-center"
                            data-content="Edit"
                          >
                            <Link
                              to={{
                                pathname: "/updateTax",
                              }}
                            >
                              <BiEdit size={20} className="gh-status-Closed" />
                            </Link>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}
