import React, { Component } from "react";
import {
  Row,
  Form,
  Col,
  Button,
  Card,
  Container,
  Breadcrumb,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaHome } from "react-icons/fa";
import "./supplierProduct.css";
import MaterialService from "../../../Services/MaterialService";

export default class AddSupplierProductExcel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: "",
    };
  }
  changeHandle(e) {
    console.log("inside if");
    this.setState({ file: e.target.files[0] });
  }

  submitExcel = (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("file", this.state.file);
    formData.append("userId", "1");
    console.log("data");
    console.log(formData);
    MaterialService.addSupplierExcel(formData)
      .then((res) => {
        console.log("success");
        console.log(res.data);
        // eslint-disable-next-line react/prop-types
        this.props.history.push("/materialProduct");
      })
      .catch((res) => {
        console.log("failure");
        console.log(res.data);
      });
  };
  render() {
    const ColoredLine = ({ color }) => (
      <hr
        style={{
          color: color,
          backgroundColor: color,
          marginTop: 0,
          height: 1,
        }}
      />
    );
    let { file } = this.state;
    return (
      <div className="gh-bgcolor gh-dashboard-bgimg">
        <Container fluid>
          <Row className="mx-auto">
            <Col>
              <h4 className="py-4 h4 d-inline-block">Supplier Product</h4>
              <Breadcrumb className="gh-breadcrumb d-none">
                <Breadcrumb.Item linkProps={{ to: "/home" }} linkAs={Link}>
                  <FaHome />
                </Breadcrumb.Item>
                <Breadcrumb.Item linkProps={{ to: "/home" }} linkAs={Link}>
                  Material
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  linkProps={{ to: "/supplierProduct" }}
                  linkAs={Link}
                >
                  Supplier Product
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  Add Supplier Product Excel
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
        </Container>

        <Container className="homeComponent pb-5">
          <Card>
            <Card.Header className="px-5 gh-card-header">
              <Card.Title className="card-title mb-0 text-left">
                <h4 className="mb-0">Add Supplier Product Excel</h4>
              </Card.Title>
            </Card.Header>
            <Card.Body className="p-5">
              <Form>
                <Form.Group>
                  <Form.File
                    className="position-relative"
                    required
                    name="file"
                    label="Please choose excel to upload"
                    vaulue={file}
                    onChange={(e) => this.changeHandle(e)}
                    feedbackTooltip
                  />
                </Form.Group>
                <Row className="">
                  <Col>
                    <Link to="/supplierProduct">
                      <Button className="mt-3" variant="gh-light">
                        Back
                      </Button>
                    </Link>
                    <Button
                      className="mt-3 ml-3"
                      variant="gh-success"
                      type="submit"
                      onClick={this.submitExcel}
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Container>
      </div>
    );
  }
}
