import React, { Component } from "react";
import {
  Row,
  Table,
  Col,
  Card,
  Container,
  Breadcrumb,
  Button,
  Form,
  Modal,
} from "react-bootstrap";
import { FaHome } from "react-icons/fa";
import { BiEdit } from "react-icons/bi";
import { MdDone } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Link } from "react-router-dom";
import "./order.css";
import CustomerOrderService from "../../Services/CustomerOrderService";
import MasterDataService from "../../Services/MasterDataService";
import CustomerService from "../../Services/CustomerService";
const loggedIn = sessionStorage.getItem("loggedInUser");
import AuthenticationService from "../../Services/AuthenticationService";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

export default class OrderStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      corporates: [],
      customers: [],
      stores: [],
      years: [],
      weeks: [],
      orders: [],
      data: {
        corporateId: String,
        customerId: String,
        storeId: String,
        year: String,
        weekNumber: String,
        orderStatus: String,
      },
    };
  }

  openModal = () => {
    this.setState({ openModal: true });
  };

  closeModal = () => {
    this.setState({ openModal: false });
  };

  componentDidMount() {
    console.log("Mount");
    let demoRes = {
      data: {
        corporates: [
          {
            corporateId: 1,
            corporateName: "DEMO GREENHOUSE 1",
          },
          {
            corporateId: 2,
            corporateName: "DEMO GREENHOUSE 2",
          },
          {
            corporateId: 3,
            corporateName: "DEMO GREENHOUSE 3",
          },
        ],
      },
    };
    CustomerService.getAllCorporates().then((res) => {
      // this.setState({corporates:res.data.corporates||[]});
      let userRole = AuthenticationService.getRole();
      if (userRole == "ROLE_Buyer,ROLE_Buyer,ROLE_Buyer") {
        this.setState({ corporates: demoRes.data.corporates || [] });
      } else {
        this.setState({ corporates: res.data.corporates || [] });
      }
    });
    MasterDataService.getAllYears()
      .then((res) => {
        this.setState({ years: res.data || [] });
      })
      .catch((res) => {
        console.log("failure in mount getAllYears");
        console.log(res.data);
      });
    MasterDataService.getAllWeeks()
      .then((res) => {
        this.setState({ weeks: res.data || [] });
      })
      .catch((res) => {
        console.log("failure in mount getAllWeeks");
        console.log(res.data);
      });
  }
  displayOrders = (e) => {
    let data = this.state.data;
    CustomerOrderService.filterOrders(data)
      .then((res) => {
        this.setState({ orders: res.data || [] });
      })
      .catch((res) => {
        console.log("failure in mount filterOrders");
        console.log(res.data);
      });
  };
  changeHandler = (e) => {
    let data = this.state.data;
    switch (e.target.name) {
      case "corporateId":
        let id = e.target.value;
        if (id != "") {
          CustomerService.getCustomerbyCorporateId(id).then((res) => {
            this.setState({ customers: res.data.customers || [] });
            this.setState({ stores: [] });
          });
          break;
        } else {
          this.setState({
            stores: [],
            customers: [],
            corporateId: "",
            customerId: "",
            storeId: "",
          });
        }
      case "customerId":
        let customerId = e.target.value;
        CustomerService.getStorebyCustomerId(customerId).then((res) => {
          this.setState({ stores: res.data.stores || [] });
        });
        break;
      default:
        break;
    }
    data[e.target.name] = e.target.value;
    this.setState({ data }, () => console.log(this.state.data));
  };
  render() {
    let userRole = AuthenticationService.getRole();
    let { corporates, customers, stores, years, weeks } = this.state;
    let { corporateId, customerId, storeId, year, weekNumber, orderStatus } =
      this.state.data;

    let that = this;

    const handleTableChange = (type, { page, sizePerPage }) => {
      this.handlePageChange(page);
    };

    const columns = [
      {
        dataField: "id",
        text: "#",
        formatter: function (cell, row, idx) {
          return <>{idx + 1}</>;
        },
      },
      {
        dataField: "orderNumber",
        text: "Order No",
      },
      {
        dataField: "storeName",
        text: "Store",
      },
      {
        dataField: "customerName",
        text: "Banner",
      },
      {
        dataField: "productName",
        text: "Product",
      },
      {
        dataField: "dispatchStatus",
        text: "Status",
        formatter: function (cell, row) {
          return (
            <>
              {row.dispatchStatus == "Dispatched" ? "Dispatched" : row.status}
            </>
          );
        },
      },
      {
        dataField: "remark",
        text: "Note",
      },
      {
        dataField: "year",
        text: "Year-Week",
        formatter: function (cell, row) {
          return (
            <>
              {row.year}-{row.weekNumber}
            </>
          );
        },
      },
      {
        dataField: "quantity",
        text: "Quantity",
      },
      {
        dataField: "insertedDate",
        text: "Inserted Date",
      },
      {
        dataField: "Action",
        isDummyField: true,
        text: "Action",
        formatter: function (cell, row) {
          return (
            <>
              <div className="">
                {row.status == "Pending" &&
                userRole != "ROLE_Buyer,ROLE_Buyer,ROLE_Buyer" ? (
                  <Link to="/order">
                    <Button size="sm">Accept Order</Button>
                  </Link>
                ) : (
                  <div></div>
                )}
                {row.status == "Completed" &&
                userRole != "ROLE_Buyer,ROLE_Buyer,ROLE_Buyer" ? (
                  <Link
                    to={{
                      pathname: "/addInvoice",
                      state: { id: row.orderId },
                    }}
                  >
                    <Button size="sm" variant="gh-light">
                      Close Order
                    </Button>
                  </Link>
                ) : (
                  <span></span>
                )}
                {row.dispatchStatus == "Dispatched" ? (
                  <Button
                    size="sm"
                    variant="gh-success"
                    onClick={that.openModal}
                  >
                    Track Order
                  </Button>
                ) : (
                  <span></span>
                )}
              </div>
            </>
          );
        },
      },
    ];

    return (
      <>
        <div className="gh-bgcolor gh-dashboard-bgimg">
          <Container fluid>
            <Row className="mx-auto">
              <Col>
                <h4 className="py-4 h4 d-inline-block">Order Status</h4>
                <Breadcrumb className="gh-breadcrumb d-none">
                  <Breadcrumb.Item linkProps={{ to: "/home" }} linkAs={Link}>
                    <FaHome />
                  </Breadcrumb.Item>
                  <Breadcrumb.Item linkProps={{ to: "/order" }} linkAs={Link}>
                    Order
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Order Status</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>
          </Container>

          <Container fluid className="homeComponent pb-5">
            <Row className="mx-auto">
              <Col>
                <div className="mb-3 mx-2">
                  <Form>
                    <Row>
                      {/* <Col lg={3} md={4} sm={6} xs={12}>
                                            <Form.Group controlId='corporateId'>
                                                <Form.Label>Corporate Name</Form.Label>
                                                <Form.Control
                                                    as='select'
                                                    name='corporateId'
                                                    onChange={this.changeHandler}
                                                    value={corporateId}
                                                >
                                                    <option value=''>All</option>
                                                    {corporates.map((corporate, idx) => (
                                                        <option key={idx} value={corporate.corporateId}>
                                                            {corporate.corporateName}
                                                        </option>
                                                    ))}
                                                </Form.Control>
                                            </Form.Group>
                                        </Col> */}
                      {userRole != "ROLE_Buyer,ROLE_Buyer,ROLE_Buyer" ? (
                        <Col lg={3} md={4} sm={6} xs={12}>
                          <Form.Group controlId="corporateId">
                            <Form.Label>Corporate Name</Form.Label>
                            <Form.Control
                              as="select"
                              name="corporateId"
                              onChange={this.changeHandler}
                              value={corporateId}
                            >
                              <option>Select corporate name</option>
                              {corporates.map((corporate, idx) => (
                                <option key={idx} value={corporate.corporateId}>
                                  {corporate.corporateName}
                                </option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                        </Col>
                      ) : (
                        <Col lg={3} md={4} sm={6} xs={12}>
                          <Form.Group controlId="corporateId">
                            <Form.Label>Greenhouse</Form.Label>
                            <Form.Control
                              as="select"
                              name="corporateId"
                              onChange={this.changeHandler}
                              value={corporateId}
                            >
                              <option>Select Greenhouse</option>
                              {corporates.map((corporate, idx) => (
                                <option key={idx} value={corporate.corporateId}>
                                  {corporate.corporateName}
                                </option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                        </Col>
                      )}
                      <Col lg={3} md={4} sm={6} xs={12}>
                        <Form.Group controlId="customerId">
                          <Form.Label>Banner Name</Form.Label>
                          <Form.Control
                            as="select"
                            name="customerId"
                            onChange={this.changeHandler}
                            value={customerId}
                          >
                            <option value="">All</option>
                            {customers.map((customer, idx) => (
                              <option key={idx} value={customer.customerId}>
                                {customer.customerName}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col lg={3} md={4} sm={6} xs={12}>
                        <Form.Group controlId="storeId">
                          <Form.Label>Store Name</Form.Label>
                          <Form.Control
                            as="select"
                            name="storeId"
                            onChange={this.changeHandler}
                            value={storeId}
                          >
                            <option value="">All</option>
                            {stores.map((store, idx) => (
                              <option key={idx} value={store.storeId}>
                                {store.storeName}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col lg={3} md={4} sm={6} xs={12}>
                        <Form.Group controlId="year">
                          <Form.Label>Years</Form.Label>
                          <Form.Control
                            as="select"
                            name="year"
                            onChange={this.changeHandler}
                            value={year}
                          >
                            <option value="">All</option>
                            {years.map((year, idx) => (
                              <option key={idx} value={year}>
                                {year}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={3} md={4} sm={6} xs={12}>
                        <Form.Group controlId="weekNumber">
                          <Form.Label>Weeks</Form.Label>
                          <Form.Control
                            as="select"
                            name="weekNumber"
                            onChange={this.changeHandler}
                            value={weekNumber}
                          >
                            {weeks.map((weekNumber, idx) => (
                              <option key={idx} value={weekNumber}>
                                {weekNumber}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col lg={3} md={4} sm={6} xs={12}>
                        <Form.Group controlId="orderStatus">
                          <Form.Label>Order Status</Form.Label>
                          <Form.Control
                            as="select"
                            name="orderStatus"
                            onChange={this.changeHandler}
                            value={orderStatus}
                          >
                            <option value="">All</option>
                            <option value="Accepted">Accepted</option>
                            <option value="Pending">Started</option>
                            <option value="Completed">Completed</option>
                            <option value="Closed">Closed (Invoice)</option>
                            <option value="Cancelled">Cancelled</option>
                            <option value="Dispatched">Dispatched</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col lg={3} md={4} sm={6} xs={12}>
                        <Button
                          className="alignDown mb-3 "
                          variant="gh-success"
                          onClick={this.displayOrders}
                        >
                          Display
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </div>

                <div className="d-flex justify-content-center mt-2 mb-2">
                  <PaginationProvider
                    pagination={paginationFactory({
                      custom: true,
                      page: this.state.currentPage + 1,
                      sizePerPage: 10,
                      paginationSize: 10,
                      alwaysShowAllBtns: true,
                      totalSize: this.state.totalItems,
                    })}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <div className="w-100">
                        <BootstrapTable
                          remote
                          bordered={false}
                          keyField="orderId"
                          data={this.state.orders}
                          columns={columns}
                          wrapperClasses="gh-table"
                          onTableChange={handleTableChange}
                          {...paginationTableProps}
                        />

                        <div className="d-none justify-content-center">
                          <PaginationListStandalone {...paginationProps} />
                        </div>
                        {/* <pre>{JSON.stringify(paginationProps, null, 2) }</pre> */}
                      </div>
                    )}
                  </PaginationProvider>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div>
          <Modal
            show={that.state.openModal}
            size="xl"
            style={{ height: "100vh" }}
            className="vehicle-tracking"
            onHide={that.closeModal}
          >
            <Modal.Header closeButton>
              <Modal.Title>Live Tracking</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <iframe
                style={{ width: "100%", height: "100%" }}
                id="iframe"
                src="https://www.etechtracker.com/trackingLink.htm?token=7221%2300000020240402%2300000020240831"
              ></iframe>
            </Modal.Body>
          </Modal>
          <NotificationContainer />
        </div>
      </>
    );
  }
}
