import React, { Component } from 'react';
import {
    Row,
    Table,
    Col,
    Button,
    Card,
    Container,
    Breadcrumb,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { MdDone } from 'react-icons/md';
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaHome } from 'react-icons/fa';
import { BiEdit } from 'react-icons/bi';
import { MdDeleteForever } from 'react-icons/md';
import './user.css';
import Pagination from 'react-js-pagination';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import { NotificationContainer, NotificationManager } from 'react-notifications';
import UserService from '../../../Services/UsersService';

export default class UserComponent extends Component {
    constructor (props) {
        super(props);
        this.state = {
            users: [],
            currentPage: 0,
            totalPages: null,
            totalItems: null
        };
    }
    componentDidMount () {
        console.log('Mount');
        let activePage = this.state.currentPage;
        UserService.getAllUser(activePage).then(res => {

            res.data.users.map((item, idx)=>{
                item.id = idx + 1 + activePage * 10;
                return item;
            });

            return res;

        }).then(res => {
            this.setState({ users: res.data.users ||[]});
            const totalPages = res.data.totalPages;
            const totalItems = res.data.totalItems;

            this.setState({ totalPages: totalPages });
            this.setState({ totalItems: totalItems });
            console.log('Mount2');
            console.log(res.data);
        });
    }
    handlePageChange (pageNumber) {
        let page = pageNumber - 1;
        this.setState({ currentPage: page });
        UserService.getAllUser(page).then(res => {

            res.data.users.map((item, idx)=>{
                item.id = idx + 1 + page * 10;
                return item;
            });

            return res;

        }).then(res => {
            this.setState({ users: res.data.users ||[]});
            console.log('Mount2');
            console.log(res.data);
            const totalPages = res.data.totalPages;
            const totalItems = res.data.totalItems;

            this.setState({ totalPages: totalPages });
            this.setState({ totalItems: totalItems });
        });
    }
    enable (id) {
        UserService.enable(id).then(res => {
            console.log(res.data);
            let page = this.state.currentPage;
            NotificationManager.success('Customer has been enabled now', 'Enabled');
            UserService.getAllUser(page).then(res => {

                res.data.users.map((item, idx)=>{
                    item.id = idx + 1 + page * 10;
                    return item;
                });
    
                return res;
    
            }).then(res => {
                this.setState({ users: res.data.users ||[]});
                console.log('Mount2');
                console.log(res.data);
                const totalPages = res.data.totalPages;
                const totalItems = res.data.totalItems;

                this.setState({ totalPages: totalPages });
                this.setState({ totalItems: totalItems });
            });
        });
    }
    disable (id) {
        UserService.disable(id).then(res => {
            console.log(res.data);
            NotificationManager.warning('Customer has been disabled now', 'Disabled');
            let page = this.state.currentPage;
            UserService.getAllUser(page).then(res => {

                res.data.users.map((item, idx)=>{
                    item.id = idx + 1 + page * 10;
                    return item;
                });
    
                return res;
    
            }).then(res => {
                this.setState({ users: res.data.users ||[]});
                console.log('Mount2');
                console.log(res.data);
                const totalPages = res.data.totalPages;
                const totalItems = res.data.totalItems;

                this.setState({ totalPages: totalPages });
                this.setState({ totalItems: totalItems });
            });
        });
    }
    render() {
        
        const handleTableChange = (type, { page, sizePerPage }) => {
            this.handlePageChange (page);
        }

        const disable = (id) => {
            this.disable(id);
        }

        const enable = (id) => {
            this.enable(id);
        }

        const columns = [{
            dataField: 'id',
            text: '#',
            sort: true
          }, {
            dataField: 'firstName',
            text: 'Name'
          }, {
            dataField: 'userRole.roleName',
            text: 'Role'
          }, {
            dataField: 'phoneNumber',
            text: 'Phone', 
          }, {
            dataField: 'address.address1',
            text: 'Address'
          }, {
            dataField: 'gender',
            text: 'Gender'
          },{
            dataField: 'insertedDate',
            text: 'Inserted Date'
          }, {
            dataField: 'Action',
            isDummyField: true,
            text: 'Action',
            formatter: function(cell, row){
                return (
                    <>
                    <div className='d-flex'>
                        <div className="gh-tooltip top" data-content="Edit">
                            <Link
                                to={{
                                    pathname: '/updateUser',
                                    state: { id: row.userId }
                                }}
                            ><BiEdit size={20} className="gh-status-Closed" />
                            </Link>
                        </div>

                        {row.isDeleted ? (
                            <div className="gh-tooltip top" data-content="Enable">
                                <div
                                    className='ml-2 gh-status-Closed'
                                    onClick={() => {
                                        enable(row.userId);
                                    }}
                                >
                                    <MdDone size={20}/>
                                </div>
                            </div>
                        ) : (
                            <div className="gh-tooltip top" data-content="Disable">
                                <div
                                    className='ml-2 gh-status-Pending'
                                    onClick={() => {
                                        disable(row.userId);
                                    }}
                                >
                                    <RiDeleteBin6Line size={20}/>
                                </div>
                            </div>
                        )} 
                    </div>                   
                    </>
                );
            }
          }     
        ];

        return (
            <>
            <div className='gh-bgcolor gh-dashboard-bgimg' >

                <Container fluid >
                    <Row className="mx-auto">
                        <Col>
                            <h4 className="py-4 h4 d-inline-block">User</h4>
                            <Breadcrumb className='gh-breadcrumb d-none'>
                                <Breadcrumb.Item linkProps={{ to: "/home" }} linkAs={Link} >
                                    <FaHome />
                                </Breadcrumb.Item>
                                <Breadcrumb.Item linkProps={{ to: "/home" }} linkAs={Link} >
                                    Profiles
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>User</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </Container>

                <Container fluid className='pb-5' >                  
                    <Row className="mx-auto">
                        <Col>             
                            
                            <div className='mb-3 mx-2'>
                                <Link to='/addUser'>
                                    <Button className='mr-3' variant='gh-light'>
                                        Add User
                                    </Button>
                                </Link>
                            </div>

                            <div className='d-flex justify-content-center mt-2 mb-2'>
 
                            <PaginationProvider
                                pagination={
                                    paginationFactory({
                                        custom: true,
                                        page: this.state.currentPage + 1,
                                        sizePerPage: 10,
                                        paginationSize: 10,
                                        alwaysShowAllBtns: true,
                                        totalSize: this.state.totalItems
                                    })
                                }
                                >
                                {
                                    ({
                                    paginationProps,
                                    paginationTableProps
                                    }) => (
                                    <div className='w-100'>
                                        <BootstrapTable
                                            remote 
                                            bordered={ false }
                                            keyField="id"
                                            data={ this.state.users }
                                            columns={ columns }                                            
                                            wrapperClasses="gh-table"
                                            onTableChange={ handleTableChange }
                                            { ...paginationTableProps }
                                        />
                                        
                                        <div className='d-flex justify-content-center'>
                                            <PaginationListStandalone
                                                { ...paginationProps }
                                            />
                                        </div>
                                        {/* <pre>{JSON.stringify(paginationProps, null, 2) }</pre> */}
                                        
                                    </div>

                                    )
                                }
                            </PaginationProvider>

                            </div>

                        </Col>
                    </Row>
                </Container>
            </div>
            <div>
                <NotificationContainer />
            </div>
            </>
        );
    }
}
