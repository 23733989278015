import React, { Component } from 'react';
import { Row, 
    Table,
    Form,
    Button, 
    Col, 
    Card, 
    Container,
    OverlayTrigger,
    Tooltip, 
    Breadcrumb,
    Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaHome } from 'react-icons/fa';
import { BiEdit } from 'react-icons/bi';
import logo from '../../Images/logo-2.png';
import CustomerOrderService from '../../Services/CustomerOrderService';

export default class AddInvoice extends Component {
    constructor (props) {
        super(props);
        this.state = {
            invoiceItems:[],
            customerDetail:Object,
            storeDetail:Object,
            orderId:Number,
            currency:Number,
            subtotalCost:Number,
            totalCost:Number,
            taxCost:Number,
            invoiceNumber:String,
            tax:Number,
            invoiceDate:Date,
            shippingDate:Date,
            businessNumber:String,
            price:Number,
            id:String,
            invoiceId: String,
            // eslint-disable-next-line react/prop-types
            propsId: props.location.state.id
        };
    }
    componentDidMount () {
        let propsId=this.state.propsId;
        CustomerOrderService.getAddInvoice(propsId).then(res => {
            this.setState({ invoiceItems: res.data.invoiceItems,
                customerDetail: res.data.customerDetail,
                storeDetail: res.data.storeDetail,
                orderId: res.data.orderId,
                currency: res.data.currency,
                subtotalCost: res.data.subtotalCost,
                totalCost: res.data.totalCost,
                taxCost: res.data.taxCost,
                invoiceNumber: res.data.invoiceNumber,
                tax: res.data.tax,
                invoiceDate: res.data.invoiceDate,
                shippingDate: res.data.shippingDate,
                businessNumber: res.data.businessNumber,
                invoiceId: res.data.invoiceId
            });
        });
    }
    submit=()=>{
        console.log('inside submit');
        let id=this.state.invoiceId;
        let date={invoiceDate: this.state.invoiceDate};
        CustomerOrderService.addInvoice(date,id)
            .then(res => {
                console.log('success');
                console.log(res.data);
                // eslint-disable-next-line react/prop-types
                this.props.history.push('/orderStatus');
            })
            .catch(res => {
                console.log('failure');
                console.log(res.data);
            });
    }
    changePrice=e=>{
        console.log('inside price change handler');
        let id = this.state.invoiceId;
        let orderItemId =this.state.id;
        let price = {price: this.state.price};
        
        CustomerOrderService.updateAddInvoice(price,orderItemId,id).then(res => {
            this.setState({ 
                invoiceItems: res.data.invoiceItems,
                orderId: res.data.orderId,
                currency: res.data.currency,
                subtotalCost: res.data.subtotalCost,
                totalCost: res.data.totalCost,
                taxCost: res.data.taxCost
            });
            this.setState({ openModal: false });
        });
        
    }
    changeHandler = e => {
        this.setState({ [e.target.name]: e.target.value });
    }
    openModal = (id,price) => {
        this.setState({ openModal: true ,id:id,price:price});
        console.log(id);
    }
    closeModal = () => {
        this.setState({ openModal: false });
    }
    render() {
        let {price,invoiceNumber,invoiceDate,tax,shippingDate,businessNumber,subtotalCost,
            storeDetail,customerDetail,currency,totalCost,taxCost,invoiceItems} = this.state;
        const ColoredLine = ({ color }) => (
            <hr
                style={{
                    color: color,
                    backgroundColor: color,
                    marginTop: 0,
                    height: 1
                }}
            />
        );
        console.log(this.state.invoiceItems);
        return (

            <>
            <div className='gh-bgcolor gh-dashboard-bgimg' >
                
                <div className='container d-flex align-items-center justify-content-between' >

                    <h4 className="py-4 text-white font-weight-bold h4 d-inline-block">Invoice</h4>

                    <Breadcrumb className='gh-breadcrumb'>
                        <Breadcrumb.Item linkProps={{ to: "/home" }} linkAs={Link} >
                            <FaHome />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item linkProps={{ to: "/orderStatus" }} linkAs={Link} >
                            Order
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Add Invoice</Breadcrumb.Item>
                    </Breadcrumb>

                </div>

                <Container className='homeComponent pb-5' >                  
                    <Row className="bg-white mx-auto">
                        <Col>             
                            
                        <Form className='m-4'>
                            <Row>
                                <Col lg={4} md={6} sm={6} xs={12}>
                                    <Form.Group controlId='tax'>
                                        <Form.Label><b>HST</b></Form.Label>
                                        <Form.Control
                                            type='text'
                                            name='tax'
                                            disabled
                                            value={tax}
                                        ></Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col lg={4} md={6} sm={6} xs={12}>
                                    <Form.Group controlId='invoiceDate'>
                                        <Form.Label><b>Invoice Date</b></Form.Label>
                                        <Form.Control
                                            type='date'
                                            name='invoiceDate'
                                            onChange={this.changeHandler}
                                            value={invoiceDate}
                                        ></Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col lg={4} md={6} sm={6} xs={12}>
                                    <Form.Group controlId='shippingDate'>
                                        <Form.Label><b>Shipping Date </b></Form.Label>
                                        <Form.Control
                                            type='date'
                                            name='shippingDate'
                                            onChange={this.changeHandler}
                                            value={shippingDate}
                                        ></Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>         
                        </Form>
                        <Row className='mt-3'>
                            <Col lg={5} md={4} sm={6} xs={12}>
                                <img
                                    src={logo}
                                    width='100%'
                                    height='auto'
                                    className='d-inline-block align-top ml-4'
                                    alt='SG'
                                ></img>
                            </Col>
                            <Col lg={3} md={4} sm={0} xs={0}>
                            </Col>
                            <Col lg={4} md={4} sm={6} xs={12}>
                                <Row>
                                    <Col>
                                        <h2>INVOICE</h2>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <b>Invoice Number:</b> {invoiceNumber}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <b>Invoice Date:</b> {invoiceDate}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <b>Shipping Date:</b> {shippingDate}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <b>Business No:</b> {businessNumber}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className='mt-3 text-center'>
                            <Col>
                                <div>Sold To</div>
                                <div><b>{storeDetail.storeName}</b></div>
                                <div>{storeDetail.address1}</div>
                                <div>{storeDetail.city}</div>
                                <div>{storeDetail.province}</div>
                            </Col>
                            <Col>
                                <div>Ship To</div>
                                <div><b>{customerDetail.customerName}</b></div>
                                <div>{storeDetail.address1}</div>
                                <div>{storeDetail.city}</div>
                                <div>{storeDetail.province}</div>
                            </Col>
                        </Row>
                        <Row className='m-2 mb-4'>
                            <Col>
                                <Table className='mb-2' striped bordered={ false } hover>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Item No</th>
                                            <th>Quantity</th>
                                            <th>Description</th>
                                            <th>Unit Price</th>
                                            <th>Price</th>
                                            <th>Amount</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {invoiceItems.length === 0 ? (
                                            <div></div>
                                        ) : (
                                            invoiceItems.map((order, idx) => (
                                                <tr key={idx}>
                                                    <td>{idx+1}</td>
                                                    <td>{order.orderItemId}</td>
                                                    <td>{order.quantity}</td>
                                                    <td>{order.description}</td>
                                                    <td>{order.unitPrice}</td>
                                                    <td>{order.price}</td>
                                                    <td>{order.amount}</td>
                                                    <td className='text-center'>
                                                    <div className="gh-tooltip top" data-content="Change Price">
                                                        <span
                                                         
                                                            onClick={() => {
                                                                this.openModal(order.orderItemId,order.price);
                                                            }}
                                                        ><BiEdit size={20} className="gh-status-Closed" />
                                                        </span>
                                                    </div></td>
                                                </tr>
                                            ))
                                        )}
                                        <tr>
                                            <td colSpan="7" className='text-right'><b> Subtotal:</b></td>
                                            <td>{currency} {subtotalCost}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="7" className='text-right'><b> HST:</b></td>
                                            <td>{currency} {taxCost}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="7" className='text-right'><b> Total:</b></td>
                                            <td>{currency} {totalCost}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="8" className='text-right'><Button variant ='success'
                                                onClick={()=>this.submit()}>
                                            Submit
                                            </Button></td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <Modal
                                    className='textCenter'
                                    show={this.state.openModal}
                                    onHide={this.closeModal}
                                >
                                    <Modal.Header closeButton>Change Price</Modal.Header>
                                    <Modal.Body><Form>
                                        <Form.Group controlId='price'>
                                            <Form.Label>Price </Form.Label>
                                            <Form.Control
                                                type='number'
                                                name='price'
                                                onChange={this.changeHandler}
                                                value={price}
                                                placeholder='Price'
                                            ></Form.Control>
                                        </Form.Group>
                                    </Form></Modal.Body>
                                    <Modal.Footer>
                                        <button className='btn btn-gh-light' onClick={this.closeModal} >
                                            Close                                      
                                        </button> 
                                        <button className='btn btn-gh-success' onClick={()=>this.changePrice()} >
                                            Change
                                        </button>
                                    </Modal.Footer>
                                </Modal> 
                            </Col>
                        </Row>

                        </Col>
                    </Row>
                </Container>
            </div>

            </>
        );
    }
}
