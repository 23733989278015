import React, { Component } from 'react';
import {
    Row,
    Table,
    Col,
    Button,
    Card,
    Container,
    OverlayTrigger,
    Tooltip,
    Breadcrumb
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaHome } from 'react-icons/fa';
import { BiEdit } from 'react-icons/bi';
import { MdDeleteForever } from 'react-icons/md';
import { RiDeleteBin6Line } from "react-icons/ri";
import './supplier.css';
import { MdDone } from 'react-icons/md';

import Pagination from 'react-js-pagination';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import { NotificationContainer, NotificationManager } from 'react-notifications';
import supplierService from '../../../Services/SupplierService';

export default class SupplierComponent extends Component {
    constructor (props) {
        super(props);
        this.state = {
            suppliers: [],
            currentPage: 0,
            totalPages: null,
            totalItems: null
        };
    }

    handlePageChange (pageNumber) {
        let page = pageNumber - 1;
        this.setState({ currentPage: page });
        supplierService.getAllSupplier(page).then(res => {
            res.data.suppliers.map((item, idx)=>{
                item.id = idx + 1 + page * 10;
                return item;
            });

            return res;

        }).then(res => {
            this.setState({ suppliers: res.data.suppliers ||[]});
            const totalPages = res.data.totalPages;
            const totalItems = res.data.totalItems;

            this.setState({ totalPages: totalPages });
            this.setState({ totalItems: totalItems });
            console.log('Mount2');
            console.log(res.data);
        });
    }
    enable (id) {
        supplierService.enable(id).then(res => {
            console.log(res.data);
            let activePage = this.state.currentPage;
            NotificationManager.success('Customer has been enabled now', 'Enabled');
            supplierService.getAllSupplier(activePage).then(res => {
                res.data.suppliers.map((item, idx)=>{
                    item.id = idx + 1 + activePage * 10;
                    return item;
                });
    
                return res;
    
            }).then(res => {
                this.setState({ suppliers: res.data.suppliers ||[]});
                const totalPages = res.data.totalPages;
                const totalItems = res.data.totalItems;

                this.setState({ totalPages: totalPages });
                this.setState({ totalItems: totalItems });
                console.log('Mount2');
                console.log(res.data);
            });
        });
    }
    disable (id) {
        supplierService.disable(id).then(res => {
            console.log(res.data);
            NotificationManager.warning('Customer has been disabled now', 'Disabled');
            let activePage = this.state.currentPage;
            supplierService.getAllSupplier(activePage).then(res => {

                res.data.suppliers.map((item, idx)=>{
                    item.id = idx + 1 + activePage * 10;
                    return item;
                });
    
                return res;
    
            }).then(res => {
                this.setState({ suppliers: res.data.suppliers ||[]});
                const totalPages = res.data.totalPages;
                const totalItems = res.data.totalItems;

                this.setState({ totalPages: totalPages });
                this.setState({ totalItems: totalItems });
                console.log('Mount2');
                console.log(res.data);
            });
        });
    }
    componentDidMount () {
        console.log('Mount');
        let activePage = this.state.currentPage;
        supplierService.getAllSupplier(activePage).then(res => {
            res.data.suppliers.map((item, idx)=>{
                item.id = idx + 1 + activePage * 10;
                return item;
            });

            return res;

        }).then(res => {
            this.setState({ suppliers: res.data.suppliers ||[]});
            const totalPages = res.data.totalPages;
            const totalItems = res.data.totalItems;

            this.setState({ totalPages: totalPages });
            this.setState({ totalItems: totalItems });
            console.log('Mount2');
            console.log(res.data);
        });
    }

    render () {
        let { currentPage } = this.state;

        const handleTableChange = (type, { page, sizePerPage }) => {
            this.handlePageChange (page);
        }

        const disable = (id) => {
            this.disable(id);
        }

        const enable = (id) => {
            this.enable(id);
        }
        
        const columns = [{
            dataField: 'id',
            text: '#',
            sort: true
          }, {
            dataField: 'supplierName',
            text: 'Supplier Name'
          }, {
            dataField: 'emailAddress',
            text: 'Email'
          }, {
            dataField: 'phoneNumber',
            text: 'Phone', 
          }, {
            dataField: 'insertedDate',
            text: 'Inserted Date'
          }, {
            dataField: 'Action',
            isDummyField: true,
            text: 'Action',
            formatter: function(cell, row){
                return (
                    <>
                    <div className='d-flex'>
                        <div className="gh-tooltip top" data-content="Edit">
                            <Link
                                to={{
                                    pathname: '/updateSupplier',
                                    state: { id: row.supplierId }
                                }}
                            ><BiEdit size={20} className="gh-status-Closed" />
                            </Link>
                        </div>

                        {row.isDeleted ? (
                            <div className="gh-tooltip top" data-content="Enable">
                                <div
                                    className='ml-2 gh-status-Closed'
                                    onClick={() => {
                                        enable(row.supplierId);
                                    }}
                                >
                                    <MdDone size={20}/>
                                </div>
                            </div>
                        ) : (
                            <div className="gh-tooltip top" data-content="Disable">
                                <div
                                    className='ml-2 gh-status-Pending'
                                    onClick={() => {
                                        disable(row.supplierId);
                                    }}
                                >
                                    <RiDeleteBin6Line size={20}/>
                                </div>
                            </div>
                        )} 
                    </div>                   
                    </>
                );
            }
          }     
        ];

        return (

            <>
            <div className='gh-bgcolor gh-dashboard-bgimg' >

                <Container fluid >
                    <Row className="mx-auto">
                        <Col>
                            <h4 className="py-4 h4 d-inline-block">Supplier</h4>
                            <Breadcrumb className='gh-breadcrumb d-none'>
                                <Breadcrumb.Item linkProps={{ to: "/home" }} linkAs={Link} >
                                    <FaHome />
                                </Breadcrumb.Item>
                                <Breadcrumb.Item linkProps={{ to: "/home" }} linkAs={Link} >
                                    Profiles
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>Supplier</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </Container>

                <Container fluid className='homeComponent pb-5' >                  
                    <Row className="mx-auto">
                        <Col>             
                            
                            <div className='mb-3 mx-2'>
                                <Link to='/addSupplierExcel'>
                                    <Button className='mr-3' variant='gh-light'>
                                        Add Excel
                                    </Button>
                                </Link>

                                <Link to='/addSupplier'>
                                    <Button variant='gh-light'>Add Supplier</Button>
                                </Link>
                            </div>

                            <div className='d-flex justify-content-center mt-2 mb-2'>
 
                            <PaginationProvider
                                pagination={
                                    paginationFactory({
                                        custom: true,
                                        page: this.state.currentPage + 1,
                                        sizePerPage: 10,
                                        paginationSize: 10,
                                        alwaysShowAllBtns: true,
                                        totalSize: this.state.totalItems
                                    })
                                }
                                >
                                {
                                    ({
                                    paginationProps,
                                    paginationTableProps
                                    }) => (
                                    <div className='w-100'>
                                        <BootstrapTable
                                            remote
                                            bordered={ false }
                                            keyField="id"
                                            data={ this.state.suppliers }
                                            columns={ columns }                                            
                                            wrapperClasses="gh-table"
                                            onTableChange={ handleTableChange }
                                            { ...paginationTableProps }
                                        />
                                        
                                        <div className='d-flex justify-content-center'>
                                            <PaginationListStandalone
                                                { ...paginationProps }
                                            />
                                        </div>
                                        {/* <pre>{JSON.stringify(paginationProps, null, 2) }</pre> */}
                                        
                                    </div>

                                    )
                                }
                            </PaginationProvider>

                            </div>

                        </Col>
                    </Row>
                </Container>
            </div>
            <div>
                <NotificationContainer />
            </div>
            </>            
        );
    }
}
