import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Card,
  Container,
  Form,
  Breadcrumb,
} from "react-bootstrap";
import { FaHome } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./order.css";
import CustomerService from "../../Services/CustomerService";
import ProductService from "../../Services/ProductService";
import MasterDataService from "../../Services/MasterDataService";
import CustomerOrderService from "../../Services/CustomerOrderService";
import AuthenticationService from "../../Services/AuthenticationService";

export default class AddOrder extends Component {
  constructor(props) {
    super(props);
    this.userid = sessionStorage.getItem("userid");
    this.state = {
      corporates: [],
      customers: [],
      stores: [],
      categories: [],
      subCategories: [],
      products: [],
      years: [],
      weekNumber: [],
      valueChanged: false,
      quantity: Number,
      corporateId: Number,
      categoryId: Number,
      subCategoryId: Number,
      productId: Number,
      customerId: Number,
      storeId: Number,
      week: Number,
      year: Number,
      remark: "remark",
      barcodeString: String,
      ROLE: String,
    };
  }

  openModal = () => {
    this.setState({ openModal: true });
  };

  closeModal = () => {
    this.setState({ openModal: false });
  };

  customerChange = (e) => {
    this.changeHandler(e);
  };
  changeHandler = (e) => {
    let id = e.target.value;
    switch (e.target.name) {
      case "corporateId":
        console.log(id);
        CustomerService.getCustomerbyCorporateId(id).then((res) => {
          this.setState({ customers: res.data.customers || [] });
          this.setState({ stores: [] });
          console.log("Mount2");
          console.log(res.data);
        });
        break;
      case "customerId":
        console.log(id);
        CustomerService.getStorebyCustomerId(id).then((res) => {
          this.setState({ stores: res.data.stores || [] });
          console.log("Mount2");
          console.log(res.data);
        });
        break;
      case "categoryId":
        console.log(id);
        ProductService.getAllSubCategoriesByCategory(id).then((res) => {
          this.setState({ subCategories: res.data.subCategories || [] });
          this.setState({ products: [] });
          console.log("Mount2");
          console.log(res.data);
        });
        break;
      case "subCategoryId":
        console.log(id);
        ProductService.getAllProductsBySubCategories(id).then((res) => {
          this.setState({ products: res.data.products || [] });
          console.log("Mount2");
          console.log(res.data);
        });
        break;

      // case "barcodeString":
      // console.log(id);
      // ProductService.getAllProductsBySubCategories(id).then((res) => {
      //   this.setState({ products: res.data.products || [] });
      //   console.log("Mount2");
      //   console.log(res.data);
      // });
      // break;

      default:
        break;
    }
    this.setState({ [e.target.name]: e.target.value }, () => {
      console.log(this.state);
    });
  };
  componentDidMount() {
    console.log("Mount");

    this.setState({ ROLE: AuthenticationService.getRole() });

    let demoRes = {
      data: {
        corporates: [
          {
            corporateId: 1,
            corporateName: "DEMO GREENHOUSE 1",
          },
          {
            corporateId: 2,
            corporateName: "DEMO GREENHOUSE 2",
          },
          {
            corporateId: 3,
            corporateName: "DEMO GREENHOUSE 3",
          },
        ],
      },
    };

    CustomerService.getAllCorporates().then((res) => {
      let userRole = AuthenticationService.getRole();
      if (userRole == "ROLE_Buyer,ROLE_Buyer,ROLE_Buyer") {
        this.setState({ corporates: demoRes.data.corporates || [] });
      } else {
        this.setState({ corporates: res.data.corporates || [] });
      }
      console.log("Mount2");
      console.log(res.data);
    });
    ProductService.getAllCategoriesWithoutPagination().then((res) => {
      this.setState({ categories: res.data.categories || [] });
      console.log("Mount2");
      console.log(res.data);
    });
    MasterDataService.getAllYears().then((res) => {
      this.setState({ years: res.data || [] });
    });
    MasterDataService.getAllWeeks().then((res) => {
      this.setState({ weekNumber: res.data || [] });
    });
  }
  addOrder = (e) => {
    e.preventDefault();
    let order = {
      quantity: this.state.quantity,
      corporateId: this.state.corporateId,
      customerId: this.state.customerId,
      categoryId: this.state.categoryId,
      subCategoryId: this.state.subCategoryId,
      productId: this.state.productId,
      storeId: this.state.storeId,
      weekNumber: this.state.week,
      year: this.state.year,
      remark: this.state.remark,
      barcode: this.state.barcodeString,
      userId: this.userid,
    };
    console.log(order);
    CustomerOrderService.addCustomerOrder(order)
      .then((res) => {
        console.log("success");
        console.log(res.data);
        // eslint-disable-next-line react/prop-types
        this.props.history.push("/order");
      })
      .catch((res) => {
        console.log("failure");
        console.log(res.data);
      });
  };
  render() {
    let {
      corporates,
      customers,
      stores,
      categories,
      subCategories,
      products,
      years,
      weekNumber,
      corporateId,
      customerId,
      storeId,
      categoryId,
      subCategoryId,
      productId,
      year,
      week,
      quantity,
      barcodeString,
      ROLE,
    } = this.state;

    return (
      <>
        <div className="gh-bgcolor gh-dashboard-bgimg">
          <Container fluid>
            <Row className="mx-auto">
              <Col>
                <h4 className="py-4 h4 d-inline-block">Order</h4>
                <Breadcrumb className="gh-breadcrumb d-none">
                  <Breadcrumb.Item linkProps={{ to: "/home" }} linkAs={Link}>
                    <FaHome />
                  </Breadcrumb.Item>
                  <Breadcrumb.Item linkProps={{ to: "/home" }} linkAs={Link}>
                    Profiles
                  </Breadcrumb.Item>
                  <Breadcrumb.Item linkProps={{ to: "/Order" }} linkAs={Link}>
                    Order
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Add Order</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>
          </Container>

          <Container className="homeComponent pb-5">
            <Card>
              <Card.Header className="px-5 gh-card-header">
                <Card.Title className="card-title mb-0 text-left">
                  <h4 className="mb-0">Add Order</h4>
                </Card.Title>
              </Card.Header>
              <Card.Body className="p-5">
                <Form>
                  <Row>
                    {ROLE != "ROLE_Buyer,ROLE_Buyer,ROLE_Buyer" ? (
                      <Col lg={3} md={4} sm={6} xs={12}>
                        <Form.Group controlId="corporateId">
                          <Form.Label>Corporate Name</Form.Label>
                          <Form.Control
                            as="select"
                            name="corporateId"
                            onChange={this.changeHandler}
                            value={corporateId}
                          >
                            <option>Select corporate name</option>
                            {corporates.map((corporate, idx) => (
                              <option key={idx} value={corporate.corporateId}>
                                {corporate.corporateName}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    ) : (
                      <Col lg={3} md={4} sm={6} xs={12}>
                        <Form.Group controlId="corporateId">
                          <Form.Label>Greenhouse</Form.Label>
                          <Form.Control
                            as="select"
                            name="corporateId"
                            onChange={this.changeHandler}
                            value={corporateId}
                          >
                            <option>Select Greenhouse</option>
                            {corporates.map((corporate, idx) => (
                              <option key={idx} value={corporate.corporateId}>
                                {corporate.corporateName}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    )}

                    <Col lg={3} md={4} sm={6} xs={12}>
                      <Form.Group controlId="customerId">
                        <Form.Label>Banner Name</Form.Label>
                        <Form.Control
                          as="select"
                          name="customerId"
                          onChange={this.changeHandler}
                          value={customerId}
                        >
                          <option>Select Banner name</option>
                          {customers.map((customer, idx) => (
                            <option key={idx} value={customer.customerId}>
                              {customer.customerName}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col lg={3} md={4} sm={6} xs={12}>
                      <Form.Group controlId="storeId">
                        <Form.Label>Store Name</Form.Label>
                        <Form.Control
                          as="select"
                          name="storeId"
                          onChange={this.changeHandler}
                          value={storeId}
                        >
                          <option>Select store</option>
                          {stores.map((store, idx) => (
                            <option key={idx} value={store.storeId}>
                              {store.storeName}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col lg={3} md={4} sm={6} xs={12}>
                      <Form.Group controlId="year">
                        <Form.Label>Years</Form.Label>
                        <Form.Control
                          as="select"
                          name="year"
                          onChange={this.changeHandler}
                          value={year}
                        >
                          <option>Select year</option>
                          {years.map((year, idx) => (
                            <option key={idx} value={year}>
                              {year}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} md={4} sm={6} xs={12}>
                      <Form.Group controlId="categoryId">
                        <Form.Label>Season</Form.Label>
                        <Form.Control
                          as="select"
                          name="categoryId"
                          onChange={this.changeHandler}
                          value={categoryId}
                        >
                          <option>Select Season </option>
                          {categories.map((category, idx) => (
                            <option key={idx} value={category.id}>
                              {category.categoryName}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col lg={3} md={4} sm={6} xs={12}>
                      <Form.Group controlId="subCategoryId">
                        <Form.Label>Category Name</Form.Label>
                        <Form.Control
                          as="select"
                          name="subCategoryId"
                          onChange={this.changeHandler}
                          value={subCategoryId}
                        >
                          <option>Select Category name</option>
                          {subCategories.map((subCategory, idx) => (
                            <option key={idx} value={subCategory.id}>
                              {subCategory.subCategoryName}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col lg={3} md={4} sm={6} xs={12}>
                      <Form.Group controlId="productId">
                        <Form.Label>Products</Form.Label>
                        <Form.Control
                          as="select"
                          name="productId"
                          onChange={this.changeHandler}
                          value={productId}
                        >
                          <option>Select product</option>
                          {products.map((product, idx) => (
                            <option key={idx} value={product.productId}>
                              {product.productName}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col lg={3} md={4} sm={6} xs={12}>
                      <Form.Group controlId="week">
                        <Form.Label>Weeks</Form.Label>
                        <Form.Control
                          as="select"
                          name="week"
                          onChange={this.changeHandler}
                          value={week}
                        >
                          <option>Select week</option>
                          {weekNumber.map((week, idx) => (
                            <option key={idx} value={week}>
                              {week}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} md={4} sm={6} xs={12}>
                      <Form.Group controlId="quantity">
                        <Form.Label> Quantity</Form.Label>
                        <Form.Control
                          type="number"
                          name="quantity"
                          onChange={this.changeHandler}
                          value={quantity}
                          placeholder="Enter quantity"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col lg={3} md={4} sm={6} xs={12}>
                      <Form.Group controlId="barcodeString">
                        <Form.Label>Barcode</Form.Label>
                        <Form.Control
                          type="text"
                          name="barcodeString"
                          onChange={this.changeHandler}
                          value={barcodeString}
                          placeholder="Barcode String"
                          autoComplete="off"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="">
                    <Col className="text-right">
                      <Link to="/order">
                        <Button className="mt-3" variant="gh-light">
                          Back
                        </Button>
                      </Link>
                      <Button
                        className="mt-3 ml-3"
                        variant="gh-success"
                        type="submit"
                        onClick={this.addOrder}
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </>
    );
  }
}
