import React, { Component } from "react";
import {
  Row,
  Table,
  Card,
  Container,
  Form,
  Breadcrumb,
  Col,
  Button,
  Modal,
  Alert,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaHome } from "react-icons/fa";
import CustomerOrderService from "../../Services/CustomerOrderService";
import ZoneService from "../../Services/ZoneService";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";

export default class ShopComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      id: String,
      barcode: String,
      zone: String,
      zones: [],
      spaceRequired: String,
      spaceAvailable: String,
    };
  }
  openModal = (id) => {
    this.setState({ openModal: true });
    this.setState({ id: id });
    console.log(id);
  };
  handlePageChange(pageNumber) {
    let page = pageNumber - 1;
    // this.setState({ currentPage: page });
    // CustomerOrderService.getShopData(page).then(res => {
    //     this.setState({ categories: res.data.categories||[] });
    //     const totalPages = res.data.totalPages;
    //     const totalItems = res.data.totalItems;

    //     this.setState({ totalPages: totalPages });
    //     this.setState({ totalItems: totalItems });
    //     console.log('Mount2');
    //     console.log(res.data);
    // });
  }
  closeModal = () => {
    this.setState({ openModal: false });
  };
  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      console.log(this.state);
    });
  };
  acceptOrder = () => {
    let dataVal = {
      orderId: this.state.id,
      zoneId: this.state.zone,
    };
    CustomerOrderService.completeOrderItem(dataVal)
      .then((res) => {
        console.log("success");
        console.log(res.data);
        CustomerOrderService.getShopData()
          .then((res) => {
            this.setState({ orders: res.data || [] });
          })
          .catch((res) => {
            console.log("failure in mount filterOrders");
            console.log(res.data);
          });
      })
      .catch((res) => {
        console.log("failure");
        console.log(res.data);
      });
    this.setState({ openModal: false });
  };
  componentDidMount() {
    console.log("Mount");
    CustomerOrderService.getShopData()
      .then((res) => {
        this.setState({ orders: res.data || [] });
      })
      .catch((res) => {
        console.log("failure in mount ");
        console.log(res.data);
      });
    ZoneService.getAllZonesWithoutPagination()
      .then((res) => {
        this.setState({ zones: res.data.zones || [] });
        console.log(res.data.zones);
      })
      .catch((res) => {
        console.log("failure in getting zones");
        console.log(res.data);
      });
  }
  render() {
    let { orders, barcode, zones, zone, spaceRequired } = this.state;

    let that = this;

    const handleTableChange = (type, { page, sizePerPage }) => {
      this.handlePageChange(page);
    };

    const columns = [
      {
        dataField: "id",
        text: "#",
        formatter: function (cell, row, idx) {
          return <>{idx + 1}</>;
        },
      },
      {
        dataField: "orderNumber",
        text: "Order No",
      },
      {
        dataField: "remark",
        text: "Note",
      },
      {
        dataField: "categoryName",
        text: "Season",
      },
      {
        dataField: "subCategoryName",
        text: "Category",
      },
      {
        dataField: "productName",
        text: "Product",
      },
      //   {
      //     dataField: 'insertedDate',
      //     text: 'Variant Name'
      //   },
      {
        dataField: "quantity",
        text: "Required Qty",
      },
      {
        dataField: "completedQuantity",
        text: "Completed Qty",
      },
      {
        dataField: "Action",
        isDummyField: true,
        text: "Action",
        formatter: function (cell, row) {
          return (
            <button
              className="btn btn-gh-success "
              onClick={() => that.openModal(row.orderId)}
            >
              Start
            </button>
          );
        },
      },
    ];

    return (
      <>
        <div className="gh-bgcolor gh-dashboard-bgimg">
          <Container fluid>
            <Row className="mx-auto">
              <Col>
                <h4 className="py-4 h4 d-inline-block">Shop</h4>
                <Breadcrumb className="gh-breadcrumb d-none">
                  <Breadcrumb.Item linkProps={{ to: "/shop" }} linkAs={Link}>
                    <FaHome />
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Shop</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>
          </Container>

          <Container fluid className="homeComponent pb-5">
            <Row className="d-none">
              <Col>
                <Form className="d-flex">
                  <Form.Group controlId="barcode">
                    <Form.Label>Barcode </Form.Label>
                    <Form.Control
                      type="text"
                      name="barcode"
                      onChange={this.changeHandler}
                      value={barcode}
                      placeholder="Scan your barcode"
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group controlId="zone" className="ml-2">
                    <Form.Label>Zone</Form.Label>
                    <Form.Control
                      as="select"
                      name="zone"
                      onChange={this.changeHandler}
                      value={zone}
                    >
                      <option>Select zone</option>
                      {zones.map((value, idx) => (
                        <option key={idx} value={value.zoneId}>
                          {value.zoneName}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group controlId="zone" className="ml-2">
                    <Form.Label>Space Required</Form.Label>
                    <Form.Control
                      type="text"
                      name="SpaceRequired"
                      onChange={this.changeHandler}
                      value={50}
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group controlId="zone" className="ml-2">
                    <Form.Label>Space Available</Form.Label>
                    <Form.Control
                      type="text"
                      name="SpaceAvailable"
                      value={1296}
                      placeholder=""
                    ></Form.Control>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
            <Row className="mx-auto py-3">
              <Col className="gh-table">
                <PaginationProvider
                  pagination={paginationFactory({
                    custom: true,
                    page: this.state.currentPage + 1,
                    sizePerPage: 10,
                    paginationSize: 10,
                    alwaysShowAllBtns: true,
                    totalSize: this.state.totalItems,
                  })}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <div className="w-100">
                      <BootstrapTable
                        remote
                        keyField="id"
                        bordered={false}
                        data={orders}
                        columns={columns}
                        wrapperClasses="gh-table"
                        onTableChange={handleTableChange}
                        {...paginationTableProps}
                      />

                      <div className="d-none justify-content-center">
                        <PaginationListStandalone {...paginationProps} />
                      </div>
                      {/* <pre>{JSON.stringify(paginationProps, null, 2) }</pre> */}
                    </div>
                  )}
                </PaginationProvider>

                <Modal
                  className=""
                  show={this.state.openModal}
                  onHide={this.closeModal}
                >
                  <Modal.Header closeButton>Product</Modal.Header>
                  <Modal.Body>
                    <Form className="">
                      <Form.Group controlId="barcode">
                        <Form.Label>Barcode </Form.Label>
                        <Form.Control
                          type="text"
                          name="barcode"
                          onChange={this.changeHandler}
                          value={barcode}
                          placeholder="Scan your barcode"
                        ></Form.Control>
                      </Form.Group>
                      <Form.Group controlId="zone">
                        <Form.Label>Zone</Form.Label>
                        <Form.Control
                          as="select"
                          name="zone"
                          onChange={this.changeHandler}
                          value={zone}
                        >
                          <option>Select zone</option>
                          {zones.map((value, idx) => (
                            <option key={idx} value={value.zoneId}>
                              {value.zoneName}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Form>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="gh-light" onClick={this.closeModal}>
                      Close
                    </Button>
                    <Button
                      variant="gh-success"
                      onClick={() => this.acceptOrder()}
                    >
                      Submit
                    </Button>
                  </Modal.Footer>
                </Modal>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}
