import React, { Component } from "react";
import "./header.css";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import {
  FaPortrait,
  FaStoreAlt,
  FaTruckMoving,
  FaUsers,
  FaMoneyCheckAlt,
  FaBoxes,
  FaFileInvoice,
} from "react-icons/fa";
import { BiCarousel } from "react-icons/bi";
import { RiCheckboxMultipleFill, RiAccountPinBoxFill } from "react-icons/ri";
import { ImBoxAdd } from "react-icons/im";
import { GiTakeMyMoney } from "react-icons/gi";
import { MdPersonPinCircle } from "react-icons/md";
import {
  BsBookmarksFill,
  BsBookmarkFill,
  BsBoundingBox,
  BsInboxesFill,
} from "react-icons/bs";

import { FiBox } from "react-icons/fi";
import { CgProfile } from "react-icons/cg";

import logo from "../../../Images/logo-2.png";
import pot1 from "../../../Images/pot-3.png";
import pot2 from "../../../Images/pot-2.png";
import pot3 from "../../../Images/pot-1.png";

import AuthenticationService from "../../../Services/AuthenticationService";
import { BsFillFunnelFill } from "react-icons/bs";

export default class HeaderComponent extends Component {
  render() {
    let userStatus = AuthenticationService.isUserLoggedIn();
    let userRole = AuthenticationService.getRole();
    let username = AuthenticationService.getUsername();

    return (
      <>
        <div className="px-2 border-bottom border-3 shadow-sm">
          <div className="d-flex justify-content-between align-items-center">
            <Navbar.Brand className="mt-auto">
              <Link to="/home">
                <img
                  src={logo}
                  width="200"
                  className="d-inline-block align-top py-2"
                  alt="SG"
                ></img>
              </Link>
            </Navbar.Brand>
            <Navbar
              className="header"
              variant="light"
              collapseOnSelect
              expand="lg"
              style={{ background: "#ffffff" }}
            >
              <div className="text-center">
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />

                <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav className="mr-auto" style={{ gap: "15px" }}>
                    {(userStatus && userRole == "ROLE_Administrator") ||
                      (userRole == "ROLE_Buyer,ROLE_Buyer,ROLE_Buyer" && (
                        <Nav.Item>
                          <Nav.Link as={Link} to="/home">
                            Dashboard
                          </Nav.Link>
                        </Nav.Item>
                      ))}
                    {userStatus && userRole == "ROLE_Administrator" && (
                      <NavDropdown
                        title="Profiles"
                        id="collasible-nav-dropdown"
                      >
                        <NavDropdown.Item as={Link} to="/customer">
                          Banner
                        </NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/store">
                          Store
                        </NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/supplier">
                          Supplier
                        </NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/user">
                          User
                        </NavDropdown.Item>
                      </NavDropdown>
                    )}
                    {userStatus && userRole == "ROLE_Administrator" && (
                      <NavDropdown
                        title="Configuration"
                        id="collasible-nav-dropdown"
                      >
                        <NavDropdown.Item as={Link} to="/category">
                          Season
                        </NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/subCategory">
                          Category
                        </NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/product">
                          Product
                        </NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/area">
                          Area
                        </NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/zone">
                          Zone
                        </NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/recipe">
                          Recipe
                        </NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/rack">
                          Rack
                        </NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/tax">
                          Tax Component
                        </NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/operatorAllocation">
                          Operator Allocation
                        </NavDropdown.Item>
                      </NavDropdown>
                    )}
                    {userStatus && userRole == "ROLE_Administrator" && (
                      <NavDropdown
                        title="Material"
                        id="collasible-nav-dropdown"
                      >
                        <NavDropdown.Item as={Link} to="/materialProduct">
                          Material Master
                        </NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/supplierProduct">
                          Supplier Product
                        </NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/purchaseOrder">
                          Purchase Order
                        </NavDropdown.Item>
                      </NavDropdown>
                    )}
                    {userStatus && userRole == "ROLE_Stores" && (
                      <Nav.Item>
                        <Nav.Link as={Link} to="/storeInventory">
                          STORE
                        </Nav.Link>
                      </Nav.Item>
                    )}
                    {userStatus && userRole == "ROLE_Stores" && (
                      <Nav.Item>
                        <Nav.Link as={Link} to="/materialInventory">
                          MATERIAL INVENTORY
                        </Nav.Link>
                      </Nav.Item>
                    )}
                    {userStatus &&
                      (userRole == "ROLE_Administrator" ||
                        userRole == "ROLE_Operator") && (
                        <Nav.Item>
                          <Nav.Link as={Link} to="/dispatch">
                            Dispatch
                          </Nav.Link>
                        </Nav.Item>
                      )}
                    {userStatus && userRole == "ROLE_Operator" && (
                      <Nav.Item>
                        <Nav.Link as={Link} to="/rackDashboard">
                          RACK
                        </Nav.Link>
                      </Nav.Item>
                    )}
                    {userStatus && userRole == "ROLE_Operator" && (
                      <Nav.Item>
                        <Nav.Link as={Link} to="/shop">
                          SHOP
                        </Nav.Link>
                      </Nav.Item>
                    )}
                    {userStatus && userRole == "ROLE_Operator" && (
                      <Nav.Item>
                        <Nav.Link as={Link} to="/materialRequest">
                          MATERIAL REQUEST
                        </Nav.Link>
                      </Nav.Item>
                    )}
                    {userStatus && userRole == "ROLE_Administrator" && (
                      <NavDropdown title="Order" id="collasible-nav-dropdown">
                        <NavDropdown.Item as={Link} to="/order">
                          Order
                        </NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/orderStatus">
                          Order Status
                        </NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/invoice">
                          Invoice
                        </NavDropdown.Item>
                      </NavDropdown>
                    )}
                    {userStatus &&
                      userRole == "ROLE_Buyer,ROLE_Buyer,ROLE_Buyer" && (
                        <NavDropdown title="Order" id="collasible-nav-dropdown">
                          <NavDropdown.Item as={Link} to="/addOrder">
                            Add Order
                          </NavDropdown.Item>
                          <NavDropdown.Item as={Link} to="/orderStatus">
                            Order Status
                          </NavDropdown.Item>
                          <NavDropdown.Item as={Link} to="/invoice">
                            Invoice
                          </NavDropdown.Item>
                        </NavDropdown>
                      )}
                    {userStatus && userRole == "ROLE_Administrator" && (
                      <Nav.Item>
                        <Nav.Link as={Link} to="/reports">
                          Reports
                        </Nav.Link>
                      </Nav.Item>
                    )}
                  </Nav>
                </Navbar.Collapse>
              </div>
            </Navbar>

            <div className="navbar-light">
              {!userStatus && (
                <Nav className="navbar-nav">
                  <Nav.Link as={Link} to="/">
                    <CgProfile /> Login
                  </Nav.Link>
                </Nav>
              )}
              {userStatus && (
                <Nav className="navbar-nav">
                  <Nav.Link as={Link} to="/logout">
                    <CgProfile /> {username}
                    <span> Logout</span>
                  </Nav.Link>
                </Nav>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
