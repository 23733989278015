import React, { Component } from "react";
import {
  Card,
  Form,
  Container,
  Breadcrumb,
  Button,
  Row,
  Col,
} from "react-bootstrap";
import { FaHome } from "react-icons/fa";
import { Link } from "react-router-dom";
import productService from "../../../Services/ProductService";
import zoneService from "../../../Services/ZoneService";
import "./product.css";
export default class AddProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      subCategories: [],
      zones: [],
      productName: String,
      productCode: "code",
      productDescription: String,
      barcodeString: String,
      price: Number,
      bucketSize: Number,
      requiredSpaceSize: Number,
      categoryId: Number,
      currency: "CAD",
      subCategoryId: Number,
      zoneTypeId: Number,
    };
  }
  componentDidMount() {
    console.log("Mount");
    zoneService.getAllZoneType().then((res) => {
      this.setState({ zones: res.data || [] });
      console.log("Mount2");
      console.log(res.data);
    });
    productService.getAllCategoriesWithoutPagination().then((res) => {
      this.setState({ categories: res.data.categories || [] });
      console.log("Mount2");
      console.log(res.data);
    });
  }
  changeHandler = (e) => {
    switch (e.target.name) {
      case "categoryId":
        let id = e.target.value;
        console.log(id);
        productService.getAllSubCategoriesByCategory(id).then((res) => {
          this.setState({ subCategories: res.data.subCategories || [] });
          console.log("Mount2");
          console.log(res.data);
        });
        break;
      default:
        break;
    }
    this.setState({ [e.target.name]: e.target.value }, () => {
      console.log(this.state);
    });
  };
  addProduct = (e) => {
    e.preventDefault();
    let product = {
      productName: this.state.productName,
      productCode: this.state.productCode,
      productDescription: this.state.productDescription,
      price: this.state.price,
      currency: this.state.currency,
      barcodeString: this.state.barcodeString,
      bucketSize: this.state.bucketSize,
      requiredSpaceSize: this.state.requiredSpaceSize,
      categoryId: this.state.categoryId,
      subCategoryId: this.state.subCategoryId,
      zoneTypeId: this.state.zoneTypeId,
    };
    console.log(product);
    productService
      .addProduct(product)
      .then((res) => {
        console.log("success");
        console.log(res.data);
        // eslint-disable-next-line react/prop-types
        this.props.history.push("/product");
      })
      .catch((res) => {
        console.log("failure");
        console.log(res.data);
      });
  };
  render() {
    let {
      productName,
      categories,
      subCategories,
      barcodeString,
      productDescription,
      price,
      bucketSize,
      categoryId,
      zoneTypeId,
      requiredSpaceSize,
      zones,
      subCategoryId,
    } = this.state;
    const ColoredLine = ({ color }) => (
      <hr
        style={{
          color: color,
          backgroundColor: color,
          marginTop: 0,
          height: 1,
        }}
      />
    );
    return (
      <>
        <div className="gh-bgcolor gh-dashboard-bgimg">
          <Container fluid>
            <Row className="mx-auto">
              <Col>
                <h4 className="py-4 h4 d-inline-block">Product</h4>
                <Breadcrumb className="gh-breadcrumb d-none">
                  <Breadcrumb.Item linkProps={{ to: "/home" }} linkAs={Link}>
                    <FaHome />
                  </Breadcrumb.Item>
                  <Breadcrumb.Item linkProps={{ to: "/home" }} linkAs={Link}>
                    Configuration
                  </Breadcrumb.Item>
                  <Breadcrumb.Item linkProps={{ to: "/product" }} linkAs={Link}>
                    Product
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Add Product</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>
          </Container>

          <Container className="homeComponent pb-5">
            <Card>
              <Card.Header className="px-5 gh-card-header">
                <Card.Title className="card-title mb-0 text-left">
                  <h4 className="mb-0">Add Product</h4>
                </Card.Title>
              </Card.Header>
              <Card.Body className="p-5">
                <Form>
                  <Row>
                    <Col lg={6} md={6} sm={6} xs={12}>
                      <Form.Group controlId="productName">
                        <Form.Label>Product Name </Form.Label>
                        <Form.Control
                          type="text"
                          name="productName"
                          onChange={this.changeHandler}
                          value={productName}
                          placeholder="Product Name"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col lg={6} md={6} sm={6} xs={12}>
                      <Form.Group controlId="categoryId">
                        <Form.Label>Season </Form.Label>
                        <Form.Control
                          as="select"
                          name="categoryId"
                          onChange={this.changeHandler}
                          value={categoryId}
                        >
                          <option>Select Season</option>
                          {categories.map((category, idx) => (
                            <option key={idx} value={category.id}>
                              {category.categoryName}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} md={6} sm={6} xs={12}>
                      <Form.Group controlId="subCategoryId">
                        <Form.Label>Category </Form.Label>
                        <Form.Control
                          as="select"
                          name="subCategoryId"
                          onChange={this.changeHandler}
                          value={subCategoryId}
                        >
                          <option>Select Category</option>
                          {subCategories.map((subCategory, idx) => (
                            <option key={idx} value={subCategory.id}>
                              {subCategory.subCategoryName}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col lg={6} md={6} sm={6} xs={12}>
                      <Form.Group controlId="productDescription">
                        <Form.Label>Product Description</Form.Label>
                        <Form.Control
                          type="textarea"
                          rows="4"
                          name="productDescription"
                          onChange={this.changeHandler}
                          value={productDescription}
                          placeholder="Plant Description"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} md={6} sm={6} xs={12}>
                      <Form.Group controlId="price">
                        <Form.Label>Price</Form.Label>
                        <Form.Control
                          type="number"
                          name="price"
                          onChange={this.changeHandler}
                          value={price}
                          placeholder="Price"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col lg={6} md={6} sm={6} xs={12}>
                      <Form.Group controlId="bucketSize">
                        <Form.Label>Basket Size</Form.Label>
                        <Form.Control
                          type="number"
                          name="bucketSize"
                          onChange={this.changeHandler}
                          value={bucketSize}
                          placeholder="Basket Size"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} md={6} sm={6} xs={12}>
                      <Form.Group controlId="requiredSpaceSize">
                        <Form.Label>Space Required (Sq.Ft/Unit)</Form.Label>
                        <Form.Control
                          type="number"
                          name="requiredSpaceSize"
                          onChange={this.changeHandler}
                          value={requiredSpaceSize}
                          placeholder="Product Size"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col lg={6} md={6} sm={6} xs={12}>
                      <Form.Group controlId="zoneTypeId">
                        <Form.Label>Zone Type </Form.Label>
                        <Form.Control
                          as="select"
                          name="zoneTypeId"
                          onChange={this.changeHandler}
                          value={zoneTypeId}
                          placeholder="Zone Type"
                        >
                          <option>Select Zone Type</option>
                          {zones.map((zone, idx) => (
                            <option key={idx} value={zone.zonetypeId}>
                              {zone.zonetype}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-right">
                      <Link to="/product">
                        <Button className="mt-3" variant="gh-light">
                          Back
                        </Button>
                      </Link>
                      <Button
                        className="mt-3 ml-3"
                        variant="gh-success"
                        type="submit"
                        onClick={this.addProduct}
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </>
    );
  }
}
