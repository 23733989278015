import React, { Component } from "react";
import "./store.css";
import {
  Row,
  Col,
  Tabs,
  Tab,
  Container,
  Card,
  Table,
  OverlayTrigger,
  Tooltip,
  Button,
  Form,
  Modal,
  Breadcrumb,
} from "react-bootstrap";
import { FaHome } from "react-icons/fa";
import { Link } from "react-router-dom";
import { MdDone } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdDeleteForever } from "react-icons/md";
import MaterialOrder from "../../Services/MaterialOrder";
import Pagination from "react-js-pagination";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";

export default class StoreInventoryComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stores: [],
      closedStores: [],
      closedCurrentPage: 0,
      closedTotalPages: null,
      closedTotalItems: null,
      id: String,
      rejectRemark: String,
      acceptRemark: String,
      quantity: Number,
      currentPage: 0,
      totalPages: null,
      totalItems: null,
    };
  }
  handlePageChange(pageNumber) {
    let page = pageNumber - 1;
    this.setState({ closedCurrentPage: page });
    MaterialOrder.getAllOpenStore(page).then((res) => {
      this.setState({ closedStores: res.data.storeOrders || [] });
      console.log("Mount2");
      console.log(res.data);
      const totalPages = res.data.totalPages;
      const totalItems = res.data.totalItems;

      this.setState({ closedTotalPages: totalPages });
      this.setState({ closedTotalItems: totalItems });
    });
  }
  handleclosedPageChange(pageNumber) {
    let page = pageNumber - 1;
    this.setState({ currentPage: page });
    MaterialOrder.getAllClosedStore(page).then((res) => {
      this.setState({ stores: res.data.storeOrders || [] });
      console.log("Mount2 closed");
      console.log(res.data);
      const totalPages = res.data.totalPages;
      const totalItems = res.data.totalItems;

      this.setState({ totalPages: totalPages });
      this.setState({ totalItems: totalItems });
    });
  }
  acceptOrder = (e) => {
    let id = this.state.id;
    let order = {
      remark: this.state.acceptRemark,
      suppliedQuantity: this.state.quantity,
    };
    MaterialOrder.approveStoreOrder(order, id).then((res) => {
      console.log(res.data);
      this.setState({ openAcceptModal: false });
      let activePage = this.state.currentPage;
      MaterialOrder.getAllOpenStore(activePage).then((res) => {
        this.setState({ stores: res.data.storeOrders || [] });
        console.log("Mount2");
        console.log(res.data);
        const totalPages = res.data.totalPages;
        const totalItems = res.data.totalItems;
        this.setState({ totalPages: totalPages });
        this.setState({ totalItems: totalItems });
      });
      let closedactivePage = this.state.closedCurrentPage;
      MaterialOrder.getAllClosedStore(closedactivePage).then((res) => {
        this.setState({ closedStores: res.data.storeOrders || [] });
        console.log("Mount2");
        console.log(res.data);
        const totalPages = res.data.totalPages;
        const totalItems = res.data.totalItems;

        this.setState({ closedTotalPages: totalPages });
        this.setState({ closedTotalItems: totalItems });
      });
    });
  };
  rejectOrder = (e) => {
    let id = this.state.id;
    let order = {
      remark: this.state.rejectRemark,
    };
    MaterialOrder.rejectStoreOrder(order, id).then((res) => {
      console.log(res.data);
      this.setState({ openModal: false });
    });
  };
  componentDidMount() {
    console.log("Mount");
    let activePage = this.state.currentPage;
    MaterialOrder.getAllOpenStore(activePage).then((res) => {
      this.setState({ stores: res.data.storeOrders || [] });
      console.log("Mount2");
      console.log(res.data);
      const totalPages = res.data.totalPages;
      const totalItems = res.data.totalItems;

      this.setState({ totalPages: totalPages });
      this.setState({ totalItems: totalItems });
    });
    let closedactivePage = this.state.closedCurrentPage;
    MaterialOrder.getAllClosedStore(closedactivePage).then((res) => {
      this.setState({ closedStores: res.data.storeOrders || [] });
      console.log("Mount2");
      console.log(res.data);
      const totalPages = res.data.totalPages;
      const totalItems = res.data.totalItems;

      this.setState({ closedTotalPages: totalPages });
      this.setState({ closedTotalItems: totalItems });
    });
  }
  openModal = (id) => {
    this.setState({ openModal: true });
    this.setState({ id: id });
    console.log(id);
  };

  closeModal = () => {
    this.setState({ openModal: false });
  };
  openAcceptModal = (id) => {
    this.setState({ openAcceptModal: true });
    this.setState({ id: id });
    console.log(id);
  };

  closeAcceptModal = () => {
    this.setState({ openAcceptModal: false });
  };
  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      console.log(this.state);
    });
  };
  render() {
    let that = this;
    let { acceptRemark, rejectRemark, quantity } = this.state;

    const handleTableChange = (type, { page, sizePerPage }) => {
      this.handlePageChange(page);
    };

    const handleclosedTableChange = (type, { page, sizePerPage }) => {
      this.handleclosedPageChange(page);
    };

    const columns = [
      {
        dataField: "materialName",
        text: "Material Name",
      },
      {
        dataField: "totalInventory",
        text: "Total Inventory",
      },
      {
        dataField: "orderQuantity",
        text: "Quantity",
      },
      {
        dataField: "areaName",
        text: "Area Name",
      },
      {
        dataField: "operatorName",
        text: "Operator Name",
      },
      {
        dataField: "insertedDate",
        text: "Request Date",
      },
      {
        dataField: "Action",
        isDummyField: true,
        text: "Action",
        formatter: function (cell, row) {
          return (
            <>
              <div className="d-flex">
                <div className="gh-tooltip top" data-content="Accept">
                  <div
                    className="ml-2"
                    onClick={() => {
                      that.openAcceptModal(row.storeOrderId);
                    }}
                  >
                    <MdDone size={20} />
                  </div>
                </div>
                <div className="gh-tooltip top" data-content="Reject">
                  <div
                    className="ml-2 gh-status-Pending"
                    onClick={() => {
                      that.openModal(row.storeOrderId);
                    }}
                  >
                    <RiDeleteBin6Line size={20} />
                  </div>
                </div>
              </div>
            </>
          );
        },
      },
    ];

    const closedStorescolumns = [
      {
        dataField: "materialName",
        text: "Material Name",
      },
      {
        dataField: "orderQuantity",
        text: "Requested Quantity",
      },
      {
        dataField: "areaName",
        text: "Area Name",
      },
      {
        dataField: "operatorName",
        text: "Operator Name",
      },
      {
        dataField: "requestedDate",
        text: "Request Date",
      },
      {
        dataField: "suppliedQuantity",
        text: "Provided Quantity",
      },
      {
        dataField: "closedDate",
        text: "Close Date",
      },
      {
        dataField: "remark",
        text: "Remark",
      },
      {
        dataField: "isRejected",
        isDummyField: true,
        text: "Is Rejected",
        formatter: function (cell, row) {
          return <>{row.isRejected ? "Yes" : "No"}</>;
        },
      },
    ];

    return (
      <>
        <div className="gh-bgcolor gh-dashboard-bgimg">
          <Container fluid>
            <Row className="mx-auto">
              <Col>
                <h4 className="py-4 h4 d-inline-block">Stores</h4>
                <Breadcrumb className="gh-breadcrumb d-none">
                  <Breadcrumb.Item
                    linkProps={{ to: "/storeInventory" }}
                    linkAs={Link}
                  >
                    <FaHome />
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Store</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>
          </Container>

          <Container fluid className="homeComponent pb-5">
            <Row className="mx-auto py-3">
              <Col>
                <Tabs defaultActiveKey="openOrder" id="controlled-tab-example">
                  <Tab eventKey="openOrder" title="Open Order">
                    <Container fluid className="mainStore">
                      <Row>
                        <Card className="w-100">
                          <PaginationProvider
                            pagination={paginationFactory({
                              custom: true,
                              page: this.state.currentPage + 1,
                              sizePerPage: 10,
                              paginationSize: 10,
                              alwaysShowAllBtns: true,
                              totalSize: 1, // this.state.totalItems
                            })}
                          >
                            {({ paginationProps, paginationTableProps }) => (
                              <div className="w-100">
                                <BootstrapTable
                                  remote
                                  bordered={false}
                                  keyField="id"
                                  data={this.state.stores}
                                  columns={columns}
                                  wrapperClasses="gh-table"
                                  onTableChange={handleTableChange}
                                  {...paginationTableProps}
                                />
                                <div className="d-flex justify-content-center">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </div>
                            )}
                          </PaginationProvider>

                          <Modal
                            className="textCenter"
                            show={this.state.openModal}
                            onHide={this.closeModal}
                          >
                            <Modal.Header closeButton>
                              Reject Order
                            </Modal.Header>
                            <Modal.Body>
                              <Form>
                                <Form.Group controlId="rejectRemark">
                                  <Form.Label>Remark </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="rejectRemark"
                                    onChange={this.changeHandler}
                                    value={rejectRemark}
                                    placeholder="Remark"
                                  ></Form.Control>
                                </Form.Group>
                              </Form>
                            </Modal.Body>
                            <Modal.Footer>
                              <Button
                                variant="secondary"
                                onClick={this.closeModal}
                              >
                                Close
                              </Button>
                              <Button
                                variant="danger"
                                onClick={this.rejectOrder}
                              >
                                Submit
                              </Button>
                            </Modal.Footer>
                          </Modal>

                          <Modal
                            className="textCenter"
                            show={this.state.openAcceptModal}
                            onHide={this.closeAcceptModal}
                          >
                            <Modal.Header closeButton>
                              Accept Order
                            </Modal.Header>
                            <Modal.Body>
                              <Form>
                                <Form.Group controlId="quantity">
                                  <Form.Label>Quantity </Form.Label>
                                  <Form.Control
                                    type="number"
                                    name="quantity"
                                    onChange={this.changeHandler}
                                    value={quantity}
                                    placeholder="Quantity"
                                  ></Form.Control>
                                </Form.Group>
                                <Form.Group controlId="acceptRemark">
                                  <Form.Label>Remark </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="acceptRemark"
                                    onChange={this.changeHandler}
                                    value={acceptRemark}
                                    placeholder="Remark"
                                  ></Form.Control>
                                </Form.Group>
                              </Form>
                            </Modal.Body>
                            <Modal.Footer>
                              <Button
                                variant="secondary"
                                onClick={this.closeAcceptModal}
                              >
                                Close
                              </Button>
                              <Button
                                variant="success"
                                onClick={this.acceptOrder}
                              >
                                Submit
                              </Button>
                            </Modal.Footer>
                          </Modal>
                        </Card>
                      </Row>
                    </Container>
                  </Tab>
                  <Tab eventKey="closedOrder" title="Closed Order">
                    <Container fluid className="mainStore">
                      <Row>
                        <Card className="customerCard">
                          <PaginationProvider
                            pagination={paginationFactory({
                              custom: true,
                              page: this.state.closedCurrentPage + 1,
                              sizePerPage: 10,
                              paginationSize: 10,
                              alwaysShowAllBtns: true,
                              totalSize: this.state.closedTotalItems,
                            })}
                          >
                            {({ paginationProps, paginationTableProps }) => (
                              <div className="w-100">
                                <BootstrapTable
                                  remote
                                  keyField="id"
                                  bordered={false}
                                  data={this.state.closedStores}
                                  columns={closedStorescolumns}
                                  wrapperClasses="gh-table"
                                  onTableChange={handleclosedTableChange}
                                  {...paginationTableProps}
                                />
                                <div className="d-flex justify-content-center">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </div>
                            )}
                          </PaginationProvider>
                        </Card>
                      </Row>
                    </Container>
                  </Tab>
                </Tabs>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}
