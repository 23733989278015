import axios from "axios";

import AuthorizationService from "../Services/AuthenticationService";

// const API_URL = 'http://localhost:8092';
// const API_URL ='http://erpde-Publi-WSTWK7GSVILE-1821530495.ap-south-1.elb.amazonaws.com';

// const API_URL = "http://35.200.212.170"; // /customerorder/api/
const API_URL = process.env.REACT_APP_BASE_URL;
const PRODUCT_API_URL = process.env.REACT_APP_BASE_URL;
class CustomerOrderService {
  addCustomerOrderExcel(formdata) {
    let token = AuthorizationService.getToken();

    return axios.post(`${API_URL}/customerorder/api/customerorders/upload`, formdata, {
      headers: {
        Authorization: `${token}`,
      },
    });
  }

  downloadFileByCustomerId(id) {
    let token = AuthorizationService.getToken();

    return axios.get(`${API_URL}/customerorder/api/customerorders/download-file?`, {
      headers: {
        Authorization: `${token}`,
      },

      params: {
        customerId: 5,
      },
    });
  }

  filterOrders(data) {
    let token = AuthorizationService.getToken();

    return axios.post(`${API_URL}/customerorder/api/customerorders/filter`, data, {
      headers: {
        "Content-Type": "application/json",

        Authorization: `${token}`,
      },
    });
  }
  getShopData() {
    let token = AuthorizationService.getToken();

    return axios.get(`${API_URL}/customerorder/api/customerorders/shop`, {
      headers: {
        "Content-Type": "application/json",

        Authorization: `${token}`,
      },
    });
  }

  cancelOrder(id) {
    let token = AuthorizationService.getToken();

    return axios.put(`${API_URL}/customerorder/api/customerorders/${id}/cancel`, {
      headers: {
        Authorization: `${token}`,
      },
    });
  }

  addCustomerOrder(data) {
    let token = AuthorizationService.getToken();

    return axios.post(`${API_URL}/customerorder/api/customerorders`, data, {
      headers: {
        "Content-Type": "application/json",

        Authorization: `${token}`,
      },
    });
  }
  getAllInvoices() {
    let token = AuthorizationService.getToken();

    return axios.get(`${API_URL}/customerorder/api/invoices`, {
      headers: {
        Authorization: `${token}`,
      },
    });
  }

  acceptOrder(orders) {
    let token = AuthorizationService.getToken();

    return axios.put(`${API_URL}/customerorder/api/customerorders/accept`, orders, {
      headers: {
        Authorization: `${token}`,
      },
    });
  }
  getAllNonDispatch(pageNumber) {
    let token = AuthorizationService.getToken();

    return axios.get(`${API_URL}/customerorder/api/customerorders/non-dispatched`, {
      headers: {
        Authorization: `${token}`,
      },
      params: {
        pageNo: `${pageNumber}`,
        pageSize: 10,
      },
    });
  }
  getAllDispatch(pageNumber) {
    let token = AuthorizationService.getToken();

    return axios.get(`${API_URL}/customerorder/api/customerorders/dispatched`, {
      headers: {
        Authorization: `${token}`,
      },
      params: {
        pageNo: `${pageNumber}`,
        pageSize: 10,
      },
    });
  }
  dispatchOrder(data) {
    let token = AuthorizationService.getToken();

    return axios.post(`${API_URL}/customerorder/api/customerorders/dispatch`, data, {
      headers: {
        "Content-Type": "application/json",

        Authorization: `${token}`,
      },
    });
  }
  getAddInvoice(id) {
    let token = AuthorizationService.getToken();
    return axios.get(`${API_URL}/customerorder/api/invoices/${id}`, {
      headers: {
        Authorization: `${token}`,
      },
    });
  }
  updateAddInvoice(price, orderItemId, id) {
    let token = AuthorizationService.getToken();
    return axios.put(`${API_URL}/customerorder/api/invoices/${id}/invoice-items/${orderItemId}`, price, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    });
  }
  addInvoice(date, id) {
    let token = AuthorizationService.getToken();
    return axios.put(`${API_URL}/customerorder/api/invoices/${id}`, date, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    });
  }
  completeOrderItem(data) {
    let token = AuthorizationService.getToken();
    return axios.post(`${API_URL}/customerorder/api/customerorders/complete-orderitem/`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    });
  }

  productRecipe(id) {
    let token = AuthorizationService.getToken();
    return axios.get(`${PRODUCT_API_URL}/productrecipe/api/productrecipes?productId=${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    });
  }

  downloadInvoice(id) {
    var data = {
      invoiceId: id,
    };

    let token = AuthorizationService.getToken();

    return axios.post(`${API_URL}/customerorder/api/download-invoice`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
      responseType: "arraybuffer",
    });
  }

  getAllInvoicesV1(id) {
    let token = AuthorizationService.getToken();

    return axios.get(`${API_URL}/customerorder/api/invoices/corporate/${id}`, {
      headers: {
        Authorization: `${token}`,
      },
    });
  }

  // stage list
  getOrderStages() {

    let token = AuthorizationService.getToken();

    return axios.get(`${API_URL}/customerorder/api/stages`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    });
  }

  getOrderSchedule(orderId) {

    let token = AuthorizationService.getToken();

    return axios.get(`${API_URL}/customerorder/api/customerorders/${orderId}/stages`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    });
  }

  addOrderStages(data, orderId, userId) {

    let token = AuthorizationService.getToken();

    return axios.post(`${API_URL}/customerorder/api/customerorders/${orderId}/stages?userId=${userId}`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    });
  }
}

export default new CustomerOrderService();
