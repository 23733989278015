import React, { Component } from "react";
import {
  Row,
  Table,
  Col,
  Tooltip,
  OverlayTrigger,
  Button,
  Card,
  Container,
  Breadcrumb,
} from "react-bootstrap";
import { FaHome } from "react-icons/fa";
import { BiEdit } from "react-icons/bi";
import { Link } from "react-router-dom";
import "./category.css";
import productService from "../../../Services/ProductService";
import Pagination from "react-js-pagination";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

export default class SubCategoryComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      currentPage: 0,
      totalPages: null,
      totalItems: null,
    };
  }
  handlePageChange(pageNumber) {
    let page = pageNumber - 1;
    this.setState({ currentPage: page });
    productService.getAllSubCategories(page).then((res) => {
      this.setState({ categories: res.data.subCategories || [] });
      const totalPages = res.data.totalPages;
      const totalItems = res.data.totalItems;

      this.setState({ totalPages: totalPages });
      this.setState({ totalItems: totalItems });
      console.log("Mount2");
      console.log(res.data);
    });
  }
  componentDidMount() {
    console.log("Mount");
    let activePage = this.state.currentPage;
    productService.getAllSubCategories(activePage).then((res) => {
      this.setState({ categories: res.data.subCategories || [] });
      const totalPages = res.data.totalPages;
      const totalItems = res.data.totalItems;

      this.setState({ totalPages: totalPages });
      this.setState({ totalItems: totalItems });
      console.log("Mount2");
      console.log(res.data);
    });
  }
  render() {
    let { currentPage } = this.state;

    const handleTableChange = (type, { page, sizePerPage }) => {
      this.handlePageChange(page);
    };

    const columns = [
      {
        dataField: "id",
        text: "#",
        sort: true,
      },
      {
        dataField: "categoryName",
        text: "Season Name",
      },
      {
        dataField: "subCategoryName",
        text: "Category Name",
      },
      {
        dataField: "insertedDate",
        text: "Inserted Date",
      },
      {
        dataField: "Action",
        isDummyField: true,
        text: "Action",
        formatter: function (cell, row) {
          return (
            <>
              <div className="d-flex">
                <div className="gh-tooltip top" data-content="Edit">
                  <Link
                    to={{
                      pathname: "/updateSubCategory",
                      state: { id: row.id },
                    }}
                  >
                    <BiEdit size={20} className="gh-status-Closed" />
                  </Link>
                </div>
              </div>
            </>
          );
        },
      },
    ];

    return (
      <>
        <div className="gh-bgcolor gh-dashboard-bgimg">
          <Container fluid>
            <Row className="mx-auto">
              <Col>
                <h4 className="py-4 h4 d-inline-block">Category</h4>
                <Breadcrumb className="gh-breadcrumb d-none">
                  <Breadcrumb.Item linkProps={{ to: "/home" }} linkAs={Link}>
                    <FaHome />
                  </Breadcrumb.Item>
                  <Breadcrumb.Item linkProps={{ to: "/home" }} linkAs={Link}>
                    Configuration
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Category</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>
          </Container>

          <Container fluid className="homeComponent pb-5">
            <Row className="mx-auto">
              <Col>
                <div className="mb-3 mx-2">
                  <Link to="/addSubCategory">
                    <Button className="mr-3" variant="gh-light">
                      Add Category
                    </Button>
                  </Link>
                </div>

                <div className="d-flex justify-content-center mt-2 mb-2">
                  <PaginationProvider
                    pagination={paginationFactory({
                      custom: true,
                      page: this.state.currentPage + 1,
                      sizePerPage: 10,
                      paginationSize: 10,
                      alwaysShowAllBtns: true,
                      totalSize: this.state.totalItems,
                    })}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <div className="w-100">
                        <BootstrapTable
                          remote
                          bordered={false}
                          keyField="id"
                          data={this.state.categories}
                          columns={columns}
                          wrapperClasses="gh-table"
                          onTableChange={handleTableChange}
                          {...paginationTableProps}
                        />

                        <div className="d-flex justify-content-center">
                          <PaginationListStandalone {...paginationProps} />
                        </div>
                        {/* <pre>{JSON.stringify(paginationProps, null, 2) }</pre> */}
                      </div>
                    )}
                  </PaginationProvider>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div>
          <NotificationContainer />
        </div>
      </>
    );
  }
}
