import React, { Component } from "react";
import {
  Row,
  Table,
  Col,
  Button,
  Card,
  Container,
  OverlayTrigger,
  Tooltip,
  Breadcrumb,
} from "react-bootstrap";
import { FaHome } from "react-icons/fa";
import { BiEdit } from "react-icons/bi";
import { MdDeleteForever } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Link } from "react-router-dom";
import "./materialProduct.css";
import { MdDone } from "react-icons/md";
import MaterialService from "../../../Services/MaterialService";
import Pagination from "react-js-pagination";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

export default class MaterialProductComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      materials: [],
      currentPage: 0,
      totalPages: null,
      totalItems: null,
    };
  }
  handlePageChange(pageNumber) {
    let page = pageNumber - 1;
    this.setState({ currentPage: page });
    MaterialService.getAllMaterials(page)
      .then((res) => {
        res.data.materials.map((item, idx) => {
          item.id = idx + 1 + page * 10;
          return item;
        });

        return res;
      })
      .then((res) => {
        this.setState({ materials: res.data.materials || [] });
        const totalPages = res.data.totalPages;
        const totalItems = res.data.totalItems;

        this.setState({ totalPages: totalPages });
        this.setState({ totalItems: totalItems });
        console.log("Mount2");
        console.log(res.data);
      });
  }

  enable(id) {
    MaterialService.enable(id).then((res) => {
      console.log(res.data);
      let page = this.state.currentPage;
      NotificationManager.success("Material has been enabled now", "Enabled");
      MaterialService.getAllMaterials(page)
        .then((res) => {
          res.data.materials.map((item, idx) => {
            item.id = idx + 1 + page * 10;
            return item;
          });

          return res;
        })
        .then((res) => {
          this.setState({ materials: res.data.materials || [] });
          const totalPages = res.data.totalPages;
          const totalItems = res.data.totalItems;

          this.setState({ totalPages: totalPages });
          this.setState({ totalItems: totalItems });
          console.log("Mount2");
          console.log(res.data);
        });
    });
  }
  disable(id) {
    MaterialService.disable(id).then((res) => {
      console.log(res.data);
      NotificationManager.warning("Material has been disabled now", "Disabled");
      let page = this.state.currentPage;
      MaterialService.getAllMaterials(page)
        .then((res) => {
          res.data.materials.map((item, idx) => {
            item.id = idx + 1 + page * 10;
            return item;
          });

          return res;
        })
        .then((res) => {
          this.setState({ materials: res.data.materials || [] });
          const totalPages = res.data.totalPages;
          const totalItems = res.data.totalItems;

          this.setState({ totalPages: totalPages });
          this.setState({ totalItems: totalItems });
          console.log("Mount2");
          console.log(res.data);
        });
    });
  }
  componentDidMount() {
    console.log("Mount");
    let activePage = this.state.currentPage;
    MaterialService.getAllMaterials(activePage)
      .then((res) => {
        res.data.materials.map((item, idx) => {
          item.id = idx + 1 + activePage * 10;
          return item;
        });

        return res;
      })
      .then((res) => {
        this.setState({ materials: res.data.materials || [] });
        const totalPages = res.data.totalPages;
        const totalItems = res.data.totalItems;

        this.setState({ totalPages: totalPages });
        this.setState({ totalItems: totalItems });
        console.log("Mount2");
        console.log(res.data);
      });
  }
  render() {
    let { currentPage } = this.state;

    const handleTableChange = (type, { page, sizePerPage }) => {
      this.handlePageChange(page);
    };

    const disable = (id) => {
      this.disable(id);
    };

    const enable = (id) => {
      this.enable(id);
    };

    const columns = [
      {
        dataField: "id",
        text: "#",
      },
      {
        dataField: "materialName",
        text: "Product Name",
      },
      {
        dataField: "categoryName",
        text: "Season",
      },
      {
        dataField: "uom",
        text: "UOM",
      },
      {
        dataField: "insertedDate",
        text: "Inserted Date",
      },
      {
        dataField: "Action",
        isDummyField: true,
        text: "Action",
        formatter: function (cell, row) {
          return (
            <>
              <div className="d-flex">
                <div className="gh-tooltip top" data-content="Edit">
                  <Link
                    to={{
                      pathname: "/updateMaterialProduct",
                      state: { id: row.materialId },
                    }}
                  >
                    <BiEdit size={20} className="gh-status-Closed" />
                  </Link>
                </div>

                {row.isDeleted ? (
                  <div className="gh-tooltip top" data-content="Enable">
                    <div
                      className="ml-2 gh-status-Closed"
                      onClick={() => {
                        enable(row.materialId);
                      }}
                    >
                      <MdDone size={20} />
                    </div>
                  </div>
                ) : (
                  <div className="gh-tooltip top" data-content="Disable">
                    <div
                      className="ml-2 gh-status-Pending"
                      onClick={() => {
                        disable(row.materialId);
                      }}
                    >
                      <RiDeleteBin6Line size={20} />
                    </div>
                  </div>
                )}
              </div>
            </>
          );
        },
      },
    ];

    return (
      <>
        <div className="gh-bgcolor gh-dashboard-bgimg">
          <Container fluid>
            <Row className="mx-auto">
              <Col>
                <h4 className="py-4 h4 d-inline-block">Material Product</h4>
                <Breadcrumb className="gh-breadcrumb d-none">
                  <Breadcrumb.Item linkProps={{ to: "/home" }} linkAs={Link}>
                    <FaHome />
                  </Breadcrumb.Item>
                  <Breadcrumb.Item linkProps={{ to: "/home" }} linkAs={Link}>
                    Material
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Material Product</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>
          </Container>

          <Container fluid className="homeComponent pb-5">
            <Row className="mx-auto">
              <Col>
                <div className="mb-3 mx-2">
                  <Link to="/addMaterialProduct">
                    <Button className="mr-3" variant="gh-light">
                      Add Material Product
                    </Button>
                  </Link>
                  <Link to="/addMaterialProductExcel">
                    <Button className="mr-3" variant="gh-light">
                      Add Excel
                    </Button>
                  </Link>
                </div>

                <div className="d-flex justify-content-center mt-2 mb-2">
                  <PaginationProvider
                    pagination={paginationFactory({
                      custom: true,
                      page: this.state.currentPage + 1,
                      sizePerPage: 10,
                      paginationSize: 10,
                      alwaysShowAllBtns: true,
                      totalSize: this.state.totalItems,
                    })}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <div className="w-100">
                        <BootstrapTable
                          remote
                          keyField="id"
                          bordered={false}
                          data={this.state.materials}
                          columns={columns}
                          wrapperClasses="gh-table"
                          onTableChange={handleTableChange}
                          {...paginationTableProps}
                        />

                        <div className="d-flex justify-content-center">
                          <PaginationListStandalone {...paginationProps} />
                        </div>
                        {/* <pre>{JSON.stringify(paginationProps, null, 2) }</pre> */}
                      </div>
                    )}
                  </PaginationProvider>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div>
          <NotificationContainer />
        </div>
      </>
    );
  }
}
