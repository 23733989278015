// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../Images/background/leaf.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".login-container {\r\n  background: radial-gradient(\r\n      circle at 11% 30%,\r\n      #a7f7b9 0%,\r\n      rgba(255, 255, 255, 0) 19%\r\n    ),\r\n    radial-gradient(circle at 27% 82%, #ffa50054 0%, rgba(255, 255, 255, 0) 18%),\r\n    radial-gradient(circle at 90% 70%, #ffa50054 0%, rgba(255, 255, 255, 0) 18%);\r\n  height: 100vh;\r\n}\r\n\r\n.login-card {\r\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\r\n  background-size: 130px;\r\n  background-repeat: no-repeat;\r\n  background-position: bottom right;\r\n}\r\n", "",{"version":3,"sources":["webpack://src/Components/Authentication/login.css"],"names":[],"mappings":"AAAA;EACE;;;;;;gFAM8E;EAC9E,aAAa;AACf;;AAEA;EACE,yDAAuD;EACvD,sBAAsB;EACtB,4BAA4B;EAC5B,iCAAiC;AACnC","sourcesContent":[".login-container {\r\n  background: radial-gradient(\r\n      circle at 11% 30%,\r\n      #a7f7b9 0%,\r\n      rgba(255, 255, 255, 0) 19%\r\n    ),\r\n    radial-gradient(circle at 27% 82%, #ffa50054 0%, rgba(255, 255, 255, 0) 18%),\r\n    radial-gradient(circle at 90% 70%, #ffa50054 0%, rgba(255, 255, 255, 0) 18%);\r\n  height: 100vh;\r\n}\r\n\r\n.login-card {\r\n  background-image: url(../../Images/background/leaf.png);\r\n  background-size: 130px;\r\n  background-repeat: no-repeat;\r\n  background-position: bottom right;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
