import React, { Component } from "react";
import {
  Card,
  Container,
  Breadcrumb,
  Button,
  Row,
  Col,
  Form,
  Table,
} from "react-bootstrap";
import { FaHome } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./supplierProduct.css";
import SupplierService from "../../../Services/SupplierService";
import MaterialService from "../../../Services/MaterialService";
export default class SupplierProductComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      suppliers: [],
      materials: [],
      checkBoxList: [],
      supplierId: Number,
      supplier: String,
    };
  }
  componentDidMount() {
    console.log("Mount");
    SupplierService.getAllSupplierWithoutPagination().then((res) => {
      this.setState({ suppliers: res.data.suppliers || [] });
      console.log(res.data);
    });
  }
  changeHandler = (e) => {
    switch (e.target.name) {
      case "supplier":
        let id = e.target.value;
        console.log("Inside Switch");
        this.setState({ supplierId: e.target.value });
        MaterialService.getAllMaterialsBySupplier(id).then((res) => {
          console.log(res.data);
          this.setState({ materials: res.data.mappings || [] });
        });
        break;
      default:
        break;
    }
    this.setState({ [e.target.name]: e.target.value }, () => {
      console.log(this.state);
    });
  };
  isSelected = (materialId) => {
    var checkBox = document.getElementById(materialId);
    if (checkBox.checked === true) {
      console.log("selected");
      this.setState({
        checkBoxList: this.state.checkBoxList.concat(materialId),
      });
    } else {
      console.log("not Selected");
      this.setState((prevState) => ({
        checkBoxList: prevState.checkBoxList.filter((el) => el !== materialId),
      }));
    }
    console.log(this.state.checkBoxList);
  };
  mapProduct = () => {
    var material = {
      supplierId: Number(this.state.supplierId),
      materialIds: this.state.checkBoxList,
    };
    console.log(material);
    MaterialService.addMapping(material)
      .then((res) => {
        MaterialService.getAllMaterialsBySupplier(this.state.supplierId).then(
          (res) => {
            console.log(res.data);
            this.setState({ materials: res.data.mappings || [] });
          }
        );
      })
      .catch((res) => {
        console.log("failure");
        console.log(res.data);
      });
  };
  unMapProduct = () => {
    var material = {
      supplierId: Number(this.state.supplierId),
      materialIds: this.state.checkBoxList,
    };
    console.log(material);
    MaterialService.removeMapping(material)
      .then((res) => {
        MaterialService.getAllMaterialsBySupplier(this.state.supplierId).then(
          (res) => {
            console.log(res.data);
            this.setState({ materials: res.data.mappings || [] });
          }
        );
      })
      .catch((res) => {
        console.log("failure");
        console.log(res.data);
      });
  };
  render() {
    let { supplier, suppliers, materials } = this.state;
    const ColoredLine = ({ color }) => (
      <hr
        style={{
          color: color,
          backgroundColor: color,
          marginTop: 0,
          height: 1,
        }}
      />
    );
    return (
      <>
        <div className="gh-bgcolor gh-dashboard-bgimg">
          <Container fluid>
            <Row className="mx-auto">
              <Col>
                <h4 className="py-4 h4 d-inline-block">Supplier Product</h4>
                <Breadcrumb className="gh-breadcrumb d-none">
                  <Breadcrumb.Item linkProps={{ to: "/home" }} linkAs={Link}>
                    <FaHome />
                  </Breadcrumb.Item>
                  <Breadcrumb.Item linkProps={{ to: "/home" }} linkAs={Link}>
                    Material
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Supplier Product</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>
          </Container>

          <Container fluid className="homeComponent pb-5">
            <Row className="mx-auto">
              <Col>
                <div className="mb-3 mx-2">
                  <Form className="d-flex align-items-end">
                    <Form.Group controlId="supplier">
                      <Form.Label>Suppliers </Form.Label>
                      <Form.Control
                        as="select"
                        name="supplier"
                        onChange={this.changeHandler}
                        value={supplier}
                      >
                        <option>Select Supplier</option>
                        {suppliers.map((sup, idx) => (
                          <option key={idx} value={sup.supplierId}>
                            {sup.supplierName}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                    <Link className="mb-3" to="/addSupplierProductExcel">
                      <Button className="ml-3" variant="gh-light">
                        Add Excel
                      </Button>
                    </Link>
                  </Form>
                </div>

                <div className="d-flex justify-content-center mt-2 mb-2 gh-table">
                  <Table bordered={false}>
                    <thead>
                      <tr>
                        <th>Select</th>
                        <th>Material Id</th>
                        <th>Material Name</th>
                        <th>Mapped</th>
                      </tr>
                    </thead>
                    <tbody>
                      {materials.length == 0 ? (
                        <div></div>
                      ) : (
                        materials.map((material, idx) => (
                          <tr key={idx}>
                            <td>
                              <Form.Check
                                type="checkbox"
                                id={material.materialId}
                                value={material.materialId}
                                onClick={(e) =>
                                  this.isSelected(material.materialId)
                                }
                              />
                            </td>
                            <td>{material.materialId}</td>
                            <td>{material.materialName}</td>
                            <td>{material.mapped ? "Yes" : "No"}</td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                </div>

                <div className="mb-3">
                  {this.state.checkBoxList.length > 0 ? (
                    <div>
                      <Button
                        variant="primary"
                        onClick={() => {
                          this.mapProduct();
                        }}
                      >
                        Map Product
                      </Button>
                      <Button
                        className="ml-3"
                        variant="danger"
                        onClick={() => {
                          this.unMapProduct();
                        }}
                      >
                        Remove Product
                      </Button>
                    </div>
                  ) : (
                    <div>
                      <Button
                        variant="primary"
                        disabled
                        onClick={() => {
                          this.mapProduct();
                        }}
                      >
                        Map Product
                      </Button>
                      <Button
                        className="ml-3"
                        variant="danger"
                        disabled
                        onClick={() => {
                          this.unMapProduct();
                        }}
                      >
                        Remove Product
                      </Button>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}
