import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Button,
  Card,
  Container,
  Form,
  Breadcrumb,
} from "react-bootstrap";
import { FaHome } from "react-icons/fa";
import { Link } from "react-router-dom";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

import "./order.css";

import CustomerOrderService from "../../Services/CustomerOrderService";
import AuthenticationService from "../../Services/AuthenticationService";

export default class OrderSchedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderId: props.location.order.id,
      stageList: [], // stage name dropdown list
      orderSchedule: [], // schedule step details
      ROLE: String,
    };
    this.userid = sessionStorage.getItem("userid");
  }

  componentDidMount() {
    this.setState({ ROLE: AuthenticationService.getRole() });

    // get order stages name list
    CustomerOrderService.getOrderStages().then((res) => {
      // console.log("stage List", res.data);
      this.setState({ stageList: res.data || [] });
    });

    // get schedules
    CustomerOrderService.getOrderSchedule(this.state.orderId).then((res) => {
      // console.log("order Scheduled", res.data);
      if (res.data.length) {
        this.setState({
          orderSchedule: res.data,
        });
      } else {
        this.setState({
          orderSchedule: [
            {
              stageId: "",
              weeksToComplete: "",
              startDate: "",
            },
          ],
        });
      }
    });
  }

  addSchedule = () => {
    this.setState((prevState) => ({
      orderSchedule: [
        ...prevState.orderSchedule,
        {
          stageId: "",
          weeksToComplete: "",
          startDate: "",
        },
      ],
    }));
  };

  deleteSchedule = (id) => {
    let index = id;
    this.setState((prevState) => ({
      orderSchedule: prevState.orderSchedule.filter((_, i) => i !== index),
    }));
  };

  autoUpdateCalendar() {}
  // dropdown stage select
  handleSelectChange = (event, idx) => {
    let newschedule = this.state.orderSchedule[idx];
    newschedule.stageId = parseInt(event.target.value);

    this.setState((prevState) => ({
      orderSchedule: prevState.orderSchedule.map((schedule, index) =>
        index === idx ? newschedule : schedule
      ),
    }));
  };

  handleWeekChange = (event, idx) => {
    let newschedule = this.state.orderSchedule[idx];
    newschedule.weeksToComplete = parseInt(event.target.value);

    const updatedSchedule = this.state.orderSchedule.map((item, index) => {
      if (index === 0) {
        return item;
      } else {
        const updateDate = new Date(
          this.state.orderSchedule[index - 1].startDate
        );

        updateDate.setDate(
          updateDate.getDate() +
            this.state.orderSchedule[index - 1].weeksToComplete * 7
        );
        return { ...item, startDate: updateDate.toISOString() };
      }
    });

    this.setState((prevState) => {
      return { orderSchedule: updatedSchedule };
    });
  };

  handleDateChange = (event, idx) => {
    let newschedule = this.state.orderSchedule[idx];
    newschedule.startDate = event.target.value;

    const updatedSchedule = this.state.orderSchedule.map((item, index) => {
      if (index === 0) {
        return item;
      } else {
        const updateDate = new Date(
          this.state.orderSchedule[index - 1].startDate
        );

        updateDate.setDate(
          updateDate.getDate() +
            this.state.orderSchedule[index - 1].weeksToComplete * 7
        );
        return { ...item, startDate: updateDate.toISOString() };
      }
    });

    this.setState((prevState) => {
      return { orderSchedule: updatedSchedule };
    });

    // this.setState((prevState) => ({
    //   orderSchedule: prevState.orderSchedule.map((schedule, index) =>
    //     index === idx ? newschedule : schedule
    //   ),
    // }));
  };

  // submit
  addOrderStages = (e) => {
    e.preventDefault();
    let orderFinalSchedule = [];

    const dateValidate = (date) => {
      let dateFormat = date.substr(0, 10) + "T00:00:00.000+00:00";
      let dt = new Date(dateFormat);
      if (isNaN(dt.getTime())) {
        return null;
      } else {
        return dateFormat;
      }
    };

    const validateJsonArray = (jsonArray) => {
      for (let i = 0; i < jsonArray.length; i++) {
        const item = jsonArray[i];

        for (const key in item) {
          if (
            item[key] === "" ||
            item[key] === null ||
            Number.isNaN(item[key])
          ) {
            return `Error: Empty value found for key "${key}" in object at index ${i}`;
          }
        }
      }
      return null;
    };

    this.state.orderSchedule.forEach((schedule) => {
      orderFinalSchedule.push({
        stageId: parseInt(schedule.stageId),
        startDate: dateValidate(schedule.startDate),
        weeksToComplete: parseInt(schedule.weeksToComplete),
      });
    });

    const validationError = validateJsonArray(orderFinalSchedule);
    if (validationError) {
      NotificationManager.error("Schedule records are incorrect", "Error");
    } else {
      console.log(
        "data submitted ",
        orderFinalSchedule,
        this.state.orderId,
        this.userid
      );
      CustomerOrderService.addOrderStages(
        orderFinalSchedule,
        this.state.orderId,
        this.userid
      )
        .then((res) => {
          NotificationManager.success(
            "Schedule records updated",
            "Data Updated"
          );
        })
        .catch((res) => {
          console.log(res.data);
        });
    }
  };

  render() {
    let { stageList, orderSchedule, ROLE } = this.state;

    return (
      <>
        <div className="gh-bgcolor gh-dashboard-bgimg">
          <Container fluid>
            <Row className="mx-auto">
              <Col>
                <h4 className="py-4 h4 d-inline-block">Order</h4>
                <Breadcrumb className="gh-breadcrumb d-none">
                  <Breadcrumb.Item linkProps={{ to: "/home" }} linkAs={Link}>
                    <FaHome />
                  </Breadcrumb.Item>
                  <Breadcrumb.Item linkProps={{ to: "/home" }} linkAs={Link}>
                    Profiles
                  </Breadcrumb.Item>
                  <Breadcrumb.Item linkProps={{ to: "/Order" }} linkAs={Link}>
                    Order
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Add Order</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>
          </Container>

          <Container fluid className="homeComponent pb-5">
            <Card>
              <Card.Header className="px-5 gh-card-header">
                <Card.Title className="card-title mb-0 text-left">
                  <h4 className="mb-0">Order Schedule</h4>
                </Card.Title>
              </Card.Header>
              <Card.Body className="p-5">
                <Form>
                  {orderSchedule.map((schedule, id) => (
                    <Row className="mt-3" key={id}>
                      <Col lg={2} md={4} sm={6} xs={12}>
                        <Form.Group>
                          <Form.Label>Stage</Form.Label>
                          <Form.Control
                            as="select"
                            value={schedule.stageId}
                            onChange={(event) =>
                              this.handleSelectChange(event, id)
                            }
                          >
                            <option>Select Stage</option>
                            {stageList.map((stage, idx) => (
                              <option key={stage.stageId} value={stage.stageId}>
                                {stage.stageName}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col lg={2} md={4} sm={6} xs={12}>
                        <Form.Group controlId="traySize">
                          <Form.Label>Tray Size</Form.Label>
                          <Form.Control as="select">
                            <option value="50" selected>
                              50
                            </option>
                            <option value="100">100</option>
                            <option value="128">128</option>
                            <option value="200">200</option>
                            <option value="288">288</option>
                            <option value="512">512</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col lg={2} md={4} sm={6} xs={12}>
                        <Form.Group controlId="customerId">
                          <Form.Label>Select Week</Form.Label>
                          <Form.Control
                            as="select"
                            value={schedule.weeksToComplete}
                            onChange={(event) =>
                              this.handleWeekChange(event, id)
                            }
                          >
                            <option value="">Select Week</option>
                            <option value="1">1 Week</option>
                            <option value="2">2 Weeks</option>
                            <option value="3">3 Weeks</option>
                            <option value="4">4 Weeks</option>
                            <option value="5">5 Weeks</option>
                            <option value="6">6 Weeks</option>
                            <option value="7">7 Weeks</option>
                            <option value="8">8 Weeks</option>
                            <option value="9">9 Weeks</option>
                            <option value="10">10 Weeks</option>
                            <option value="11">11 Weeks</option>
                            <option value="12">12 Weeks</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col lg={3} md={4} sm={6} xs={12}>
                        {id == 0 && (
                          <Form.Group>
                            <Form.Label>Start Date</Form.Label>
                            <Form.Control
                              type="date"
                              value={schedule.startDate.substr(0, 10)}
                              onChange={(event) =>
                                this.handleDateChange(event, id)
                              }
                            />
                          </Form.Group>
                        )}
                        {id != 0 && (
                          <Form.Group>
                            <Form.Label>Start Date</Form.Label>
                            <Form.Control
                              readOnly
                              type="date"
                              value={schedule.startDate.substr(0, 10)}
                            />
                          </Form.Group>
                        )}
                      </Col>
                      <Col lg={3} md={4} sm={6} xs={12}>
                        <Form.Label className="text-white">Delete</Form.Label>
                        {id == 0 && (
                          <Form.Group>
                            <Form.Label className="text-white">
                              Stage
                            </Form.Label>
                            <Button
                              variant="gh-light"
                              onClick={this.addSchedule}
                            >
                              Add New Stage
                            </Button>
                          </Form.Group>
                        )}
                        {id != 0 && (
                          <Form.Group>
                            <Button
                              variant="gh-success"
                              onClick={() => this.deleteSchedule(id)}
                            >
                              X
                            </Button>
                          </Form.Group>
                        )}
                      </Col>
                    </Row>
                  ))}

                  <Row className="">
                    <Col className="text-right">
                      <Link to="/order">
                        <Button className="mt-3" variant="gh-light">
                          Back
                        </Button>
                      </Link>
                      <Button
                        className="mt-3 ml-3"
                        variant="gh-success"
                        type="submit"
                        onClick={this.addOrderStages}
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Container>
        </div>
        <div>
          <NotificationContainer />
        </div>
      </>
    );
  }
}

OrderSchedule.propTypes = {
  location: PropTypes.object,
};
