import React, { Component } from "react";
import {
  Card,
  Form,
  Container,
  Breadcrumb,
  Button,
  Row,
  Col,
} from "react-bootstrap";
import { FaHome } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./materialProduct.css";
import MaterialService from "../../../Services/MaterialService";

export default class AddmaterialProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      materialName: String,
      categoryName: String,
      uomId: String,
      userId: 1,
      quantity: 20,
      formErrors: {
        corporateName: "",
        customerName: "",
      },
      errorStatus: false,
      uoms: [],
    };
  }
  componentDidMount() {
    console.log("Mount");
    MaterialService.getAllUoms().then((res) => {
      this.setState({ uoms: res.data || [] });
      console.log("Mount2");
      console.log(res.data);
    });
  }
  changeHandler = (e) => {
    let formErrors = { ...this.state.formErrors };
    //Switch method for tackling particular formError
    switch (e.target.name) {
      case "corporateName":
        formErrors.id =
          e.target.value.length < 3 ? "minimum 3 characterss required" : "";
        break;
      case "customerName":
        formErrors.name =
          e.target.value.length < 3 ? "minimum 3 characterss required" : "";
        break;
      default:
        break;
    }
    this.setState({ formErrors, [e.target.name]: e.target.value }, () => {
      console.log(this.state);
    });
  };

  submitMaterial = (e) => {
    e.preventDefault();
    let material = {
      materialName: this.state.materialName,
      categoryName: this.state.categoryName,
      uomId: this.state.uomId,
      userId: this.state.userId,
      quantity: this.state.quantity,
    };
    MaterialService.addMaterial(material)
      .then((res) => {
        console.log("success");
        console.log(res.data);
        // eslint-disable-next-line react/prop-types
        this.props.history.push("/materialProduct");
      })
      .catch((res) => {
        console.log("failure");
        console.log(res.data);
      });
  };
  render() {
    let { materialName, categoryName, uomId, uoms } = this.state;
    const ColoredLine = ({ color }) => (
      <hr
        style={{
          color: color,
          backgroundColor: color,
          marginTop: 0,
          height: 1,
        }}
      />
    );
    return (
      <>
        <div className="gh-bgcolor gh-dashboard-bgimg">
          <Container fluid>
            <Row className="mx-auto">
              <Col>
                <h4 className="py-4 h4 d-inline-block">Material Product</h4>
                <Breadcrumb className="gh-breadcrumb d-none">
                  <Breadcrumb.Item linkProps={{ to: "/home" }} linkAs={Link}>
                    <FaHome />
                  </Breadcrumb.Item>
                  <Breadcrumb.Item linkProps={{ to: "/home" }} linkAs={Link}>
                    Configuration
                  </Breadcrumb.Item>
                  <Breadcrumb.Item
                    linkProps={{ to: "/materialProduct" }}
                    linkAs={Link}
                  >
                    Material Product
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Material Product</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>
          </Container>

          <Container className="homeComponent pb-5">
            <Card>
              <Card.Header className="px-5 gh-card-header">
                <Card.Title className="card-title mb-0 text-left">
                  <h4 className="mb-0">Add Material Product</h4>
                </Card.Title>
              </Card.Header>
              <Card.Body className="p-5">
                <Form>
                  <Row>
                    <Col lg={6} md={6} sm={6} xs={12}>
                      <Form.Group controlId="materialName">
                        <Form.Label>Material Name </Form.Label>
                        <Form.Control
                          type="text"
                          name="materialName"
                          onChange={this.changeHandler}
                          value={materialName}
                          placeholder="Material Name"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col lg={6} md={6} sm={6} xs={12}>
                      <Form.Group controlId="categoryName">
                        <Form.Label>Season Name </Form.Label>
                        <Form.Control
                          type="text"
                          name="categoryName"
                          onChange={this.changeHandler}
                          value={categoryName}
                          placeholder="Season Name"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} md={6} sm={6} xs={12}>
                      <Form.Group controlId="uomId">
                        <Form.Label>UOM</Form.Label>
                        <Form.Control
                          as="select"
                          name="uomId"
                          onChange={this.changeHandler}
                          value={uomId}
                        >
                          {uoms.length === 0 ? (
                            <option>Select UOM</option>
                          ) : (
                            uoms.map((uom, idx) => (
                              <option key={idx} value={uom.uomId}>
                                {uom.uom}
                              </option>
                            ))
                          )}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-right">
                      <Link to="/materialProduct">
                        <Button className="mt-3" variant="gh-light">
                          Back
                        </Button>
                      </Link>
                      <Button
                        className="mt-3 ml-3"
                        variant="gh-success"
                        type="submit"
                        onClick={this.submitMaterial}
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </>
    );
  }
}
